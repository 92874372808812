import { FirebaseApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { addDoc, collection, getFirestore, onSnapshot } from 'firebase/firestore';
import { getFunctions, httpsCallable } from 'firebase/functions';

export const getCheckoutUrl = async (app: FirebaseApp, priceId: string, promotionCode?: string | null): Promise<string> => {
    const auth = getAuth(app);
    const userId = auth.currentUser?.uid;
    if (!userId) throw new Error('User is not authenticated');

    const db = getFirestore(app);
    const checkoutSessionRef = collection(db, 'customers', userId, 'checkout_sessions');

    // Only include promotion code if it matches our expected code
    const shouldApplyPromo = promotionCode?.toUpperCase() === process.env.REACT_APP_STRIPE_PROMOTION_CODE?.toUpperCase();

    const docRef = await addDoc(checkoutSessionRef, {
        price: priceId,
        trial_from_plan: true,
        allow_promotion_codes: true,
        success_url: `${window.location.origin}/profile`,
        cancel_url: `${window.location.origin}/profile`,
        promotion_code: shouldApplyPromo ? process.env.REACT_APP_STRIPE_PROMOTION_CODE_ID : null,
    });

    return new Promise<string>((resolve, reject) => {
        const unsubscribe = onSnapshot(docRef, (snap) => {
            const { error, url } = snap.data() as {
                error?: { message: string };
                url?: string;
            };
            if (error) {
                unsubscribe();
                reject(new Error(`An error occurred: ${error.message}`));
            }
            if (url) {
                // console.log("Stripe Checkout URL:", url);
                unsubscribe();
                resolve(url);
            }
        });
    });
};

export const getPortalUrl = async (app: FirebaseApp): Promise<string> => {
    const auth = getAuth(app);
    const user = auth.currentUser;

    let dataWithUrl: any;
    try {
        const functions = getFunctions(app, process.env.REACT_APP_FIREBASE_FUNCTIONS_REGION!);
        const functionRef = httpsCallable(functions, process.env.REACT_APP_CREATE_STRIPE_PORTAL_LINK_FUNCTION!);
        const { data } = await functionRef({
            customerId: user?.uid,
            returnUrl: `${window.location.origin}/profile`,
        });

        // Add a type to the data
        dataWithUrl = data as { url: string };
        // console.log("Reroute to Stripe portal: ", dataWithUrl.url);
    } catch (error) {
        console.error(error);
    }

    return new Promise<string>((resolve, reject) => {
        // console.log("dataWithUrl", dataWithUrl);
        if (dataWithUrl.url) {
            resolve(dataWithUrl.url);
        } else {
            reject(new Error('No url returned'));
        }
    });
};
