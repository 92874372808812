import React, { useEffect } from 'react';
import { Container, Typography, Box, Grid, Paper, Button, Stack, useTheme, useMediaQuery } from '@mui/material';
import SubscriptionsList from './SubscriptionsList';
import DownloadPage from './DownloadPage';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import KeyboardVoiceIcon from '@mui/icons-material/KeyboardVoice';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import SecurityIcon from '@mui/icons-material/Security';
import { useScrollToSection } from '../hooks/useScrollToSection';
import FeatureSection from './FeatureSection';
import PricingSection from './PricingSection';
import SEO from './SEO';

const QuickFeature: React.FC<{ icon: React.ReactNode; title: string; description: string }> = ({ icon, title, description }) => (
    <Box sx={{ textAlign: 'center', p: 2 }}>
        <Box sx={{ color: 'primary.main', mb: 1 }}>{icon}</Box>
        <Typography variant="h6" gutterBottom>
            {title}
        </Typography>
        <Typography variant="body2" color="text.secondary">
            {description}
        </Typography>
    </Box>
);

const SonaScriptLandingPage: React.FC = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const location = useLocation();
    const scrollToSection = useScrollToSection();

    // Handle scrolling when navigating from other pages
    useEffect(() => {
        if (location.state && location.state.scrollTo) {
            scrollToSection(location.state.scrollTo);
        }
    }, [location.state, scrollToSection]);

    // Handle initial hash navigation
    useEffect(() => {
        const hash = window.location.hash;
        if (hash) {
            // Add a small delay to ensure the page is fully loaded
            setTimeout(() => {
                scrollToSection(hash);
            }, 100);
        }
    }, [scrollToSection]);

    const handleSeeHowItWorks = (e: React.MouseEvent) => {
        e.preventDefault();
        scrollToSection('#features');
    };

    const features = [
        {
            title: 'Voice-to-Text, Anywhere, Anytime',
            description: "Transform your thoughts into text instantly with SonaScript's powerful transcription engine",
            bulletPoints: [
                'Real-time transcription with high accuracy',
                'Works seamlessly across all applications',
                'Global shortcut for instant activation',
                'Support for multiple languages and accents'
            ],
            imageSrc: '/usage_master.mp4',
        },
        {
            title: 'Your Choice of Transcription Power',
            description: 'Choose the perfect balance between privacy and performance with our flexible transcription options',
            bulletPoints: [
                'Cloud-powered transcription using OpenAI for maximum accuracy',
                'Offline mode with downloaded models for complete privacy',
                'Switch between modes seamlessly',
                'Optimized performance on Apple Silicon for Local Mode'
            ],
            imageSrc: '/audiotranscriber.mp4',
        },
        {
            title: 'Smart History & Organization',
            description: 'Never lose a thought with our comprehensive transcription management system',
            bulletPoints: [
                'All transcriptions automatically locally saved and organized',
                'Edit and refine transcriptions after recording',
                'Quick shortcut to copy text to clipboard'
            ],
            imageSrc: '/history.gif',
        },
        {
            title: 'AI-Powered Text Enhancement',
            description: 'Take your transcriptions further with intelligent text processing capabilities',
            bulletPoints: [
                'Global shortcuts to process your voice notes with AI',
                'One-click translation to multiple languages',
                'Grammar and style improvement suggestions',
                'Automatic email and message formatting',
                'Custom AI prompts for unlimited possibilities!',
                'Create your custom actions, AI for AI to process your text'
            ],
            imageSrc: 'prompt.gif',
        },
        {
            title: 'Privacy and flexibility',
            description: 'Choose between cloud-powered or local LLLs for your Text Enhancements',
            bulletPoints: [
                'Cloud-powered transcription using OpenAI or Anthropic with your API key',
                'Offline mode with downloaded models for complete privacy',
                'Switch between modes seamlessly',
                'Privacy first: We don\'t store or process your data. Even with Online mode it is a direct communication with OpenAI or Anthropic API and your machine',
                'We dont store or process your data, except login and subscription info'
            ],
            imageSrc: 'LLms.mp4',
        },
        {
            title: 'Shortcuts for your productivity',
            description: 'Customize global shortcuts to instantly start transcription or apply AI actions to your text. From simple dictation to complex AI processing, all just a keystroke away',
            bulletPoints: [
                'Global shortcut to instantly transcribe anywhere - just press, speak, press again and your text is pasted into the active text field',
                'Chain voice and AI: Set shortcut to transcribe and process in one go - speak an email or jira issue and have it professionally formatted and pasted instantly',
                'Works system-wide: Use in any app, any text field, anywhere on your Mac'
            ],
            imageSrc: '/shortcuts.mp4',
        },
    ];

    return (
        <>
            <SEO 
                title="SonaScript - Transform Your Voice to Text on Mac"
                description="Transform your thoughts into text instantly with SonaScript's powerful transcription engine. Offline privacy or cloud precision, AI-powered editing, and seamless dictation anywhere on Mac"
                keywords="voice to text, transcription, dictation software, speech recognition, Mac app, AI transcription, offline transcription, privacy-focused"
            />
            <Box 
                sx={{ 
                    display: 'flex',
                    flexDirection: 'column'
                }}
            >
                {/* Hero Section */}
                <Box
                    sx={{
                        background: `linear-gradient(45deg, ${theme.palette.background.paper} 0%, ${theme.palette.background.default} 100%)`,
                        minHeight: 'calc(100vh - 48px)',
                        display: 'flex',
                        flexDirection: 'column',
                        pt: { xs: 14, md: 20 },
                        pb: { xs: 12, md: 16 },
                        position: 'relative',
                        borderBottom: '1px solid',
                        borderTop: '1px solid',
                        // borderColor: 'rgba(255, 255, 255, 0.05)',
                        borderColor: 'rgba(255, 255, 255, 0.1)',
                        '&::before': {
                            content: '""',
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            right: 0,
                            height: '200px',
                            background: `linear-gradient(180deg, ${theme.palette.background.default} 0%, transparent 100%)`,
                            opacity: 0.4,
                            pointerEvents: 'none',
                        },
                        '&::after': {
                            content: '""',
                            position: 'absolute',
                            bottom: 0,
                            left: '50%',
                            transform: 'translateX(-50%)',
                            width: '80%',
                            height: '1px',
                            background: `linear-gradient(90deg, 
                                transparent 0%, 
                                rgba(255, 255, 255, 0.05) 20%, 
                                rgba(255, 255, 255, 0.1) 50%, 
                                rgba(255, 255, 255, 0.05) 80%, 
                                transparent 100%
                            )`,
                        }
                    }}
                >
                    <Container 
                        maxWidth="lg" 
                        sx={{ 
                            display: 'flex',
                            flexDirection: 'column',
                            flex: 1,
                            justifyContent: 'center'
                        }}
                    >
                        <Grid container spacing={4} alignItems="center">
                            <Grid item xs={12} md={6}>
                                <Box sx={{ maxWidth: 600 }}>
                                    <Typography
                                        variant="h1"
                                        sx={{
                                            fontSize: { xs: '2.5rem', md: '3.5rem' },
                                            fontWeight: 'bold',
                                            mb: 2,
                                            background: `linear-gradient(45deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
                                            WebkitBackgroundClip: 'text',
                                            WebkitTextFillColor: 'transparent',
                                        }}
                                    >
                                        Transcribe Your Voice to Text – On Your Terms
                                    </Typography>
                                    <Typography
                                        variant="h2"
                                        sx={{
                                            fontSize: { xs: '1.25rem', md: '1.5rem' },
                                            color: 'text.secondary',
                                            mb: 4,
                                            fontWeight: 'normal',
                                        }}
                                    >
                                        Sonascript lets you dictate anywhere, with offline privacy or cloud precision. <br />
                                        Summarize, process or edit notes with AI directly via shortcut.
                                    </Typography>
                                    <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                                        <Button
                                            component={RouterLink}
                                            to="/download"
                                            variant="contained"
                                            size="large"
                                            color="primary"
                                            sx={{
                                                py: 1.5,
                                                px: 4,
                                                fontSize: '1.1rem',
                                                fontWeight: 'bold',
                                                '&:hover': {
                                                    transform: 'translateY(-2px)',
                                                },
                                            }}
                                        >
                                            Start Transcribing Now – Free
                                        </Button>
                                        <Button
                                            component="a"
                                            href="#features"
                                            onClick={handleSeeHowItWorks}
                                            variant="outlined"
                                            size="large"
                                            sx={{
                                                py: 1.5,
                                                px: 4,
                                                fontSize: '1.1rem',
                                            }}
                                        >
                                            See How It Works
                                        </Button>
                                    </Stack>
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Box
                                    sx={{
                                        position: 'relative',
                                        '&::before': {
                                            content: '""',
                                            position: 'absolute',
                                            top: -20,
                                            left: -20,
                                            right: -20,
                                            bottom: -20,
                                            background: `radial-gradient(circle, ${theme.palette.primary.main}22 0%, transparent 70%)`,
                                            zIndex: 0,
                                        },
                                    }}
                                >
                                    <Box
                                        component="video"
                                        autoPlay
                                        loop
                                        muted
                                        sx={{
                                            width: '100%',
                                            borderRadius: 4,
                                            boxShadow: 3,
                                            position: 'relative',
                                            zIndex: 1,
                                        }}
                                    >
                                        <source src="/usage_master.mp4" type="video/mp4" />
                                        Your browser does not support the video tag.
                                    </Box>
                                </Box>
                            </Grid>
                        </Grid>

                        {/* Quick Features */}
                        <Box sx={{ mt: { xs: 8, md: 12 } }}>
                            <Grid container spacing={4}>
                                <Grid item xs={12} md={4}>
                                    <QuickFeature
                                        icon={<KeyboardVoiceIcon sx={{ fontSize: 40 }} />}
                                        title="Instant Voice-to-Text"
                                        description="Transform your speech into text in real-time, with high accuracy and natural language processing"
                                    />
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <QuickFeature
                                        icon={<AutoAwesomeIcon sx={{ fontSize: 40 }} />}
                                        title="AI-Powered Editing"
                                        description="Enhance your text with AI-powered formatting, translation or any other processing you control"
                                    />
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <QuickFeature
                                        icon={<SecurityIcon sx={{ fontSize: 40 }} />}
                                        title="Privacy First"
                                        description="Choose offline mode for complete privacy, keeping your voice data secure on your device"
                                    />
                                </Grid>
                            </Grid>
                        </Box>
                    </Container>
                </Box>

                {/* Main Content */}
                <Box>
                    <Container maxWidth="lg">
                        <FeatureSection features={features} />
                    </Container>
                    <PricingSection />
                </Box>
            </Box>
        </>
    );
};

export default SonaScriptLandingPage;
