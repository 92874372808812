import { useState, useEffect, RefObject } from 'react';

interface TiltEffectOptions {
    perspective?: number;     // Depth of the 3D effect
    scale?: number;          // Scale on hover
    max?: number;            // Maximum rotation angle
    speed?: number;          // Speed of the animation
}

interface TiltValues {
    transform: string;
    transition: string;
    gradientPosition: string; // Add gradient position
}

export const useTiltEffect = (
    elementRef: RefObject<HTMLElement>,
    options: TiltEffectOptions = {}
): TiltValues => {
    const {
        perspective = 1000,   // Default perspective
        scale = 1.05,        // Default scale factor
        max = 15,            // Default max tilt rotation
        speed = 400,         // Default transition speed
    } = options;

    const [tiltValues, setTiltValues] = useState<TiltValues>({
        transform: 'perspective(1000px) rotateX(0deg) rotateY(0deg) scale3d(1, 1, 1)',
        transition: 'transform 200ms ease-out',
        gradientPosition: '50% 50%',
    });

    useEffect(() => {
        const element = elementRef.current;
        if (!element) return;

        const updateTilt = (e: MouseEvent) => {
            const rect = element.getBoundingClientRect();
            const centerX = rect.left + rect.width / 2;
            const centerY = rect.top + rect.height / 2;
            
            // Calculate mouse position relative to card center
            const mouseX = e.clientX - centerX;
            const mouseY = e.clientY - centerY;
            
            // Convert to rotation angles (-1 to 1, then multiply by max rotation)
            const rotateX = (mouseY / (rect.height / 2)) * -max;
            const rotateY = (mouseX / (rect.width / 2)) * max;

            // Calculate gradient position (0-100%)
            const gradientX = ((mouseX / (rect.width / 2)) + 1) * 50;
            const gradientY = ((mouseY / (rect.height / 2)) + 1) * 50;

            // Apply transform with smooth transition
            setTiltValues({
                transform: `
                    perspective(${perspective}px) 
                    rotateX(${rotateX}deg) 
                    rotateY(${rotateY}deg)
                    scale3d(${scale}, ${scale}, ${scale})
                `,
                transition: `transform ${speed}ms ease-out`,
                gradientPosition: `${gradientX}% ${gradientY}%`,
            });
        };

        const resetTilt = () => {
            setTiltValues({
                transform: `
                    perspective(${perspective}px) 
                    rotateX(0deg) 
                    rotateY(0deg)
                    scale3d(1, 1, 1)
                `,
                transition: `transform ${speed}ms ease-out`,
                gradientPosition: '50% 50%',
            });
        };

        // Add event listeners
        element.addEventListener('mousemove', updateTilt);
        element.addEventListener('mouseleave', resetTilt);
        element.addEventListener('mouseenter', updateTilt);

        // Cleanup
        return () => {
            element.removeEventListener('mousemove', updateTilt);
            element.removeEventListener('mouseleave', resetTilt);
            element.removeEventListener('mouseenter', updateTilt);
        };
    }, [elementRef, perspective, scale, max, speed]);

    return tiltValues;
}; 