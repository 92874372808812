import AppleIcon from '@mui/icons-material/Apple';
import MicrosoftIcon from '@mui/icons-material/Microsoft';
import { Box, Button, Container, Stack, Typography, useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react';

// Declare global gtag for TypeScript
declare global {
    interface Window {
        gtag: (
            command: string,
            action: string,
            params?: Record<string, any>
        ) => void;
        dataLayer: any[];
    }
}

// Layout constants
const LAYOUT = {
    NAVBAR_HEIGHT: 80, // MUI's default AppBar height
    NAVBAR_MARGIN: 0, // 1 unit of margin
    FOOTER_HEIGHT: 70, // Footer height (py: 3 = 48px) + extra space for content
} as const;

const TOTAL_OFFSET = LAYOUT.NAVBAR_HEIGHT + LAYOUT.NAVBAR_MARGIN + LAYOUT.FOOTER_HEIGHT;

interface Release {
    tag_name: string;
    assets: Array<{
        name: string;
        browser_download_url: string;
    }>;
}

const DownloadPage: React.FC<{ isMainPage?: boolean }> = ({ isMainPage = true }) => {
    const theme = useTheme();
    const [latestVersion, setLatestVersion] = useState<string>('');
    const [downloadUrls, setDownloadUrls] = useState<{
        intel: string;
        arm64: string;
    }>({ intel: '', arm64: '' });

    useEffect(() => {
        const fetchLatestRelease = async () => {
            try {
                const response = await fetch('https://api.github.com/repos/sonascript-app/sonascript-releases/releases/latest');
                const data: Release = await response.json();

                setLatestVersion(data.tag_name);

                // Find download URLs for both architectures
                const intelAsset = data.assets.find((asset) => asset.name.endsWith('.dmg') && !asset.name.includes('arm64'));
                const armAsset = data.assets.find((asset) => asset.name.includes('arm64') && asset.name.endsWith('.dmg'));

                setDownloadUrls({
                    intel: intelAsset?.browser_download_url || '',
                    arm64: armAsset?.browser_download_url || '',
                });
            } catch (error) {
                console.error('Error fetching release info:', error);
            }
        };

        fetchLatestRelease();
        
        // Track page view with additional detail that it's the download page
        if (window.gtag) {
            window.gtag('event', 'view_download_page', {
                event_category: 'engagement',
                event_label: 'Download Page View'
            });
        }
    }, []);

    const handleDownload = (url: string, architecture: string) => {
        if (!url) return;
        
        // Track download event
        if (window.gtag) {
            window.gtag('event', 'app_download', {
                event_category: 'conversion',
                event_label: `SonaScript Download ${architecture}`,
                version: latestVersion,
                architecture: architecture
            });
        }
        
        window.location.href = url;
    };

    return (
        <Box
            sx={{
                height: `calc(100vh - ${TOTAL_OFFSET}px)`,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                background: `linear-gradient(135deg, 
                    ${theme.palette.background.paper} 0%,
                    ${theme.palette.background.default} 100%
                )`,
                position: 'relative',
                '&::before': {
                    content: '""',
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    height: '200px',
                    background: `linear-gradient(180deg, ${theme.palette.background.default} 0%, transparent 100%)`,
                    opacity: 0.4,
                    pointerEvents: 'none',
                },
                '&::after': {
                    content: '""',
                    position: 'absolute',
                    bottom: 0,
                    left: 0,
                    right: 0,
                    height: '200px',
                    background: `linear-gradient(0deg, ${theme.palette.background.default} 0%, transparent 100%)`,
                    opacity: 0.4,
                    pointerEvents: 'none',
                },
            }}
        >
            <Container maxWidth="sm">
                <Stack spacing={4} alignItems="center">

                    {/* Title and Version */}
                    <Box sx={{ textAlign: 'center' }}>
                        <Typography 
                            variant="h4" 
                            component="h1" 
                            gutterBottom 
                            sx={{
                                background: `linear-gradient(135deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
                                WebkitBackgroundClip: 'text',
                                WebkitTextFillColor: 'transparent',
                                fontWeight: 'bold',
                            }}
                        >
                            Download Sonascript
                        </Typography>
                        <Typography variant="h6" color="text.secondary" sx={{ opacity: 0.8 }}>
                            Version {latestVersion}
                        </Typography>
                    </Box>

                    {/* Download Buttons */}
                    <Stack spacing={2} sx={{ width: '100%', maxWidth: 360 }}>
                        <Button
                            fullWidth
                            variant="contained"
                            color="primary"
                            size="large"
                            onClick={() => handleDownload(downloadUrls.arm64, 'Apple Silicon')}
                            disabled={!downloadUrls.arm64}
                            startIcon={<AppleIcon />}
                            sx={{
                                py: 2,
                                fontSize: '1.1rem',
                                transition: 'all 0.2s ease-in-out',
                                '&:hover': {
                                    transform: 'translateY(-2px)',
                                },
                            }}
                        >
                            Download for Mac (Apple Silicon)
                        </Button>

                        <Button
                            fullWidth
                            variant="contained"
                            color="primary"
                            size="large"
                            onClick={() => handleDownload(downloadUrls.intel, 'Intel')}
                            disabled={!downloadUrls.intel}
                            startIcon={<AppleIcon />}
                            sx={{
                                py: 2,
                                fontSize: '1.1rem',
                                transition: 'all 0.2s ease-in-out',
                                '&:hover': {
                                    transform: 'translateY(-2px)',
                                },
                            }}
                        >
                            Download for Mac (Intel)
                        </Button>

                        <Button
                            fullWidth
                            variant="outlined"
                            size="large"
                            disabled
                            startIcon={<MicrosoftIcon />}
                            sx={{
                                py: 2,
                                fontSize: '1.1rem',
                                borderColor: 'rgba(255, 255, 255, 0.1)',
                                backgroundColor: 'rgba(255, 255, 255, 0.05)',
                                color: 'text.primary',
                                '&.Mui-disabled': {
                                    color: 'rgba(255, 255, 255, 0.3)',
                                    borderColor: 'rgba(255, 255, 255, 0.1)',
                                },
                            }}
                        >
                            Windows Version (Coming Soon)
                        </Button>
                    </Stack>
                </Stack>
            </Container>
        </Box>
    );
};

export default DownloadPage;
