import React, { useState, useEffect, useRef } from 'react';
import { 
  Box, 
  Button, 
  Paper, 
  Typography, 
  Stack, 
  useTheme,
  FormGroup,
  FormControlLabel,
  Switch,
  Divider,
  alpha
} from '@mui/material';
import CookieIcon from '@mui/icons-material/Cookie';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import AnalyticsOutlinedIcon from '@mui/icons-material/AnalyticsOutlined';
import AdUnitsOutlinedIcon from '@mui/icons-material/AdUnitsOutlined';
import { useIsSafari } from '../hooks/useIsSafari';
import { keyframes } from '@mui/material';

// Cookie consent options
const COOKIE_CONSENT_KEY = 'sonascript-cookie-consent';

// Helper function to reset consent for testing purposes
export const resetCookieConsent = () => {
  localStorage.removeItem(COOKIE_CONSENT_KEY);
  window.location.reload();
};

// Add this to window object for easy testing in dev tools
if (process.env.NODE_ENV === 'development') {
  // @ts-ignore
  window.resetCookieConsent = resetCookieConsent;
}

// Subtle glow animation for the cookie icon
const pulseAnimation = keyframes`
  0% {
    filter: drop-shadow(0 0 2px rgba(0, 255, 0, 0.3));
    transform: scale(1);
  }
  50% {
    filter: drop-shadow(0 0 5px rgba(0, 255, 0, 0.5));
    transform: scale(1.05);
  }
  100% {
    filter: drop-shadow(0 0 2px rgba(0, 255, 0, 0.3));
    transform: scale(1);
  }
`;

// Utility function for Safari-specific font weights
const getFontWeight = (isSafari: boolean, defaultWeight: number) => {
  if (!isSafari) return defaultWeight;
  // Make Safari font weights thinner
  const weightMap: { [key: number]: number } = {
      700: 500, // bold -> medium
      600: 400, // semibold -> regular
      500: 300, // medium -> light
      400: 300, // regular -> light
  };
  return weightMap[defaultWeight] || defaultWeight;
};

// Get shadows based on browser
const getShadows = (isSafari: boolean) => ({
  // Primary shadows for large text (titles)
  PRIMARY: {
      LAYER_1: isSafari 
          ? '0px 1px 2px rgba(0,0,0,0.4)'
          : '0px 2px 4px rgba(0,0,0,0.8)',
      COMBINED: isSafari
          ? '0px 1px 2px rgba(0,0,0,0.4), 0px 2px 4px rgba(0,0,0,0.2)'
          : '0px 2px 4px rgba(0,0,0,0.8), 0px 4px 8px rgba(0,0,0,0.4)'
  },
  // Secondary shadows for body text
  SECONDARY: {
      NORMAL: isSafari
          ? '0px 1px 1px rgba(0,0,0,0.4)'
          : '0px 1px 2px rgba(0,0,0,0.8)'
  },
  // Icon shadows
  ICON: {
      NORMAL: isSafari
          ? 'drop-shadow(0 2px 3px rgba(0, 0, 0, 0.2))'
          : 'drop-shadow(0 3px 6px rgba(0, 0, 0, 0.4))'
  }
});

type ConsentOptions = {
  analytics_storage: 'granted' | 'denied';
  ad_storage: 'granted' | 'denied';
  ad_user_data: 'granted' | 'denied';
  ad_personalization: 'denied' | 'granted';
};

// Cookie types with descriptions
const cookieTypes = [
  {
    id: 'necessary',
    name: 'Necessary',
    description: 'Essential cookies that enable basic functionality of the website. The website cannot function properly without these cookies.',
    required: true,
    icon: <InfoOutlinedIcon fontSize="small" />,
    consentKeys: [] as string[]
  },
  {
    id: 'analytics',
    name: 'Analytics',
    description: 'These cookies help us understand how visitors interact with our website by collecting and reporting information anonymously.',
    required: false,
    icon: <AnalyticsOutlinedIcon fontSize="small" />,
    consentKeys: ['analytics_storage'] as (keyof ConsentOptions)[]
  },
  {
    id: 'advertising',
    name: 'Advertising',
    description: 'These cookies are used to track visitors across websites to display relevant advertisements based on their browsing habits.',
    required: false,
    icon: <AdUnitsOutlinedIcon fontSize="small" />,
    consentKeys: ['ad_storage', 'ad_user_data', 'ad_personalization'] as (keyof ConsentOptions)[]
  },
];

const ConsentBanner: React.FC = () => {
  const theme = useTheme();
  const isSafari = useIsSafari();
  const SHADOWS = getShadows(isSafari);
  const [showBanner, setShowBanner] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [cookiePreferences, setCookiePreferences] = useState<Record<string, boolean>>({
    necessary: true, // Always required
    analytics: true,  // Set to true by default
    advertising: true, // Set to true by default
  });
  const bannerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    // Check if user has already made a choice
    const hasConsent = localStorage.getItem(COOKIE_CONSENT_KEY);
    
    // Set default consent state when the component mounts
    if (window.gtag) {
      window.gtag('consent', 'default', {
        'ad_storage': 'granted',
        'ad_user_data': 'granted',
        'ad_personalization': 'granted',
        'analytics_storage': 'granted',
        'wait_for_update': 500
      });
      
      // Enable URL passthrough to maintain measurement when cookies are restricted
      // @ts-ignore - gtag typing issue
      window.gtag('set', 'url_passthrough', true);
      
      // Enable ads data redaction when ad_storage is denied
      // @ts-ignore - gtag typing issue
      window.gtag('set', 'ads_data_redaction', true);
    }

    // If no consent is stored, show the banner
    if (!hasConsent) {
      setShowBanner(true);
    } else {
      // If user previously gave consent, update Google tags
      const savedConsent = JSON.parse(hasConsent) as ConsentOptions;
      
      // Update cookie preferences based on saved consent
      const newPreferences = { ...cookiePreferences };
      newPreferences.analytics = savedConsent.analytics_storage === 'granted';
      newPreferences.advertising = 
        savedConsent.ad_storage === 'granted' && 
        savedConsent.ad_user_data === 'granted' && 
        savedConsent.ad_personalization === 'granted';
      
      setCookiePreferences(newPreferences);
      updateConsentState(savedConsent);
    }
  }, []);

  const updateConsentState = (consentOptions: ConsentOptions) => {
    if (window.gtag) {
      window.gtag('consent', 'update', consentOptions);
    }
  };

  const handleToggleCookieType = (cookieType: string) => {
    setCookiePreferences({
      ...cookiePreferences,
      [cookieType]: !cookiePreferences[cookieType]
    });
  };

  const handleAcceptAll = () => {
    const allPreferences = {
      necessary: true,
      analytics: true,
      advertising: true
    };
    
    setCookiePreferences(allPreferences);
    
    const consentOptions: ConsentOptions = {
      'analytics_storage': 'granted',
      'ad_storage': 'granted',
      'ad_user_data': 'granted',
      'ad_personalization': 'granted'
    };
    
    localStorage.setItem(COOKIE_CONSENT_KEY, JSON.stringify(consentOptions));
    updateConsentState(consentOptions);
    setShowBanner(false);
  };

  const handleSaveChanges = () => {
    const consentOptions: ConsentOptions = {
      'analytics_storage': cookiePreferences.analytics ? 'granted' : 'denied',
      'ad_storage': cookiePreferences.advertising ? 'granted' : 'denied',
      'ad_user_data': cookiePreferences.advertising ? 'granted' : 'denied',
      'ad_personalization': cookiePreferences.advertising ? 'granted' : 'denied'
    };
    
    localStorage.setItem(COOKIE_CONSENT_KEY, JSON.stringify(consentOptions));
    updateConsentState(consentOptions);
    setShowBanner(false);
  };

  if (!showBanner) {
    return null;
  }

  return (
    <Paper
      ref={bannerRef}
      elevation={8}
      sx={{
        position: 'fixed',
        bottom: 20,
        left: { xs: 16, md: 40 },
        right: { xs: 16, md: 40 },
        zIndex: 9999,
        p: 0,
        borderRadius: 2,
        background: `linear-gradient(135deg, 
          ${theme.palette.background.paper} 0%,
          ${theme.palette.background.default} 100%
        )`,
        border: '1px solid',
        borderColor: 'rgba(255, 255, 255, 0.1)',
        maxWidth: { sm: '100%', md: '700px' },
        mx: { md: 'auto' },
        overflow: 'hidden',
        '&::before': {
          content: '""',
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          borderRadius: 2,
          padding: '1px',
          background: `linear-gradient(45deg, ${theme.palette.primary.main}66, ${theme.palette.secondary.main}66)`,
          mask: 'linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)',
          maskComposite: 'exclude',
          pointerEvents: 'none',
        },
        boxShadow: `0 10px 30px -10px ${alpha(theme.palette.common.black, 0.5)}`
      }}
    >
      {/* Banner Header */}
      <Box sx={{ p: { xs: 2, sm: 2.5 } }}>
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
          <CookieIcon 
            sx={{ 
              mr: 1.5, 
              color: theme.palette.primary.main,
              fontSize: 24,
              animation: `${pulseAnimation} 3s ease-in-out infinite`,
              filter: SHADOWS.ICON.NORMAL
            }} 
          />
          <Typography 
            variant="h6" 
            component="h2"
            sx={{
              fontWeight: getFontWeight(isSafari, 600),
              textShadow: SHADOWS.PRIMARY.LAYER_1,
              fontSize: '1.1rem'
            }}
          >
            Cookie Consent
          </Typography>
        </Box>
        
        <Typography 
          variant="body2" 
          sx={{ 
            color: 'text.secondary', 
            mb: 1,
            fontSize: '0.85rem',
            textShadow: SHADOWS.SECONDARY.NORMAL,
            lineHeight: 1.4
          }}
        >
          We use cookies to enhance your experience on our website. Essential cookies are always active. 
          You can choose which optional cookies you want to allow.
        </Typography>

        <Button
          size="small"
          endIcon={<ExpandMoreIcon 
            sx={{ 
              transform: expanded ? 'rotate(180deg)' : 'rotate(0deg)',
              transition: 'transform 0.3s'
            }} 
          />}
          onClick={() => setExpanded(!expanded)}
          sx={{ 
            mb: 0.5,
            color: theme.palette.primary.main,
            fontSize: '0.75rem',
            padding: '2px 8px',
            minWidth: 'auto',
            '&:hover': {
              backgroundColor: 'transparent',
              textDecoration: 'underline'
            }
          }}
        >
          {expanded ? 'Hide cookie details' : 'Show cookie details'}
        </Button>
      </Box>
      
      {/* Expandable Cookie Details */}
      {expanded && (
        <Box sx={{ px: 2.5, pb: 1.5 }}>
          <Divider sx={{ mb: 1.5 }} />
          <FormGroup>
            {cookieTypes.map((cookieType) => (
              <Box key={cookieType.id} sx={{ mb: 1.5 }}>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Box sx={{ 
                      display: 'flex', 
                      alignItems: 'center',
                      color: cookieType.id === 'necessary' ? 'text.secondary' : theme.palette.primary.main,
                      filter: SHADOWS.ICON.NORMAL
                    }}>
                      {cookieType.icon}
                    </Box>
                    <Typography 
                      variant="body2" 
                      sx={{ 
                        ml: 1, 
                        fontWeight: getFontWeight(isSafari, 500),
                        fontSize: '0.85rem',
                        textShadow: SHADOWS.SECONDARY.NORMAL
                      }}
                    >
                      {cookieType.name}
                    </Typography>
                    {cookieType.required && (
                      <Typography 
                        variant="caption" 
                        sx={{ 
                          ml: 1, 
                          color: 'text.secondary',
                          fontSize: '0.7rem'
                        }}
                      >
                        (Required)
                      </Typography>
                    )}
                  </Box>
                  
                  <FormControlLabel
                    control={
                      <Switch 
                        size="small"
                        checked={cookiePreferences[cookieType.id]}
                        onChange={() => !cookieType.required && handleToggleCookieType(cookieType.id)}
                        disabled={cookieType.required}
                      />
                    }
                    label=""
                  />
                </Box>
                <Typography 
                  variant="caption" 
                  sx={{ 
                    display: 'block', 
                    color: 'text.secondary', 
                    ml: 4,
                    fontSize: '0.7rem',
                    lineHeight: 1.3
                  }}
                >
                  {cookieType.description}
                </Typography>
              </Box>
            ))}
          </FormGroup>
        </Box>
      )}
      
      {/* Buttons */}
      <Box 
        sx={{ 
          p: { xs: 2, sm: 2.5 }, 
          pt: { xs: 1.5, sm: 2 },
          bgcolor: 'rgba(0,0,0,0.15)',
          background: `linear-gradient(to bottom, 
            rgba(0,0,0,0.05) 0%,
            rgba(0,0,0,0.2) 100%
          )`
        }}
      >
        <Stack 
          direction={{ xs: 'column', sm: 'row' }} 
          spacing={1.5}
          justifyContent="flex-end"
        >
          <Button 
            variant="outlined"
            size="small"
            onClick={handleSaveChanges}
            sx={{ 
              borderColor: 'rgba(255, 255, 255, 0.3)',
              color: 'text.primary',
              py: 0.75,
              fontSize: '0.85rem',
              fontWeight: getFontWeight(isSafari, 500),
              '&:hover': {
                borderColor: 'rgba(255, 255, 255, 0.5)',
                backgroundColor: 'rgba(255, 255, 255, 0.05)'
              }
            }}
          >
            Save Preferences
          </Button>
          <Button 
            variant="contained" 
            color="primary"
            size="small"
            onClick={handleAcceptAll}
            sx={{
              py: 0.75,
              fontSize: '0.85rem',
              fontWeight: getFontWeight(isSafari, 600),
              boxShadow: 'none',
              background: `linear-gradient(45deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
              '&:hover': {
                boxShadow: 'none',
                background: `linear-gradient(45deg, ${theme.palette.primary.dark}, ${theme.palette.secondary.dark})`,
              }
            }}
          >
            Accept All Cookies
          </Button>
        </Stack>
      </Box>
    </Paper>
  );
};

export default ConsentBanner; 