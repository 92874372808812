import React, { useContext, useState } from 'react';
import { AuthContext } from '../context/AuthProvider';
import { Box, Typography, Button, CircularProgress, useTheme, Chip } from '@mui/material';
import { SubscriptionResponse } from '../types/types';
import { getPortalUrl } from '../services/StripeService';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CoffeeIcon from '@mui/icons-material/Coffee';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

interface ManagedSubscriptionProps {
    subscription: SubscriptionResponse;
}

const ManageSubscription: React.FC<ManagedSubscriptionProps> = ({ subscription }) => {
    const theme = useTheme();
    const authContext = useContext(AuthContext);
    const { app } = authContext || {};
    const [isLoadingManageSubscription, setIsLoadingManageSubscription] = useState(false);

    const { subscription_data } = subscription;

    if (!subscription_data) return null;

    const { plan, status, current_period_start, current_period_end, cancel_at_period_end } = subscription_data;

    const periodStartFormatted = new Date(current_period_start * 1000).toLocaleDateString();
    const periodEndFormatted = new Date(current_period_end * 1000).toLocaleDateString();
    const daysLeft = Math.ceil((current_period_end * 1000 - Date.now()) / (1000 * 60 * 60 * 24));

    const manageSubscription = async () => {
        if (!app) return;
        setIsLoadingManageSubscription(true);
        try {
            const portalUrl = await getPortalUrl(app);
            window.location.href = portalUrl;
        } catch (error) {
            console.error('Error getting portal URL:', error);
            setIsLoadingManageSubscription(false);
        }
    };

    return (
        <Box sx={{ width: '100%', textAlign: 'center' }}>
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    gap: 1.5,
                    mb: 3,
                }}
            >
                <CoffeeIcon
                    sx={{
                        fontSize: 32,
                        color: theme.palette.primary.main,
                    }}
                />
                <Typography
                    variant="h5"
                    sx={{
                        color: theme.palette.primary.main,
                        fontWeight: 'bold',
                    }}
                >
                    {plan}
                </Typography>
            </Box>

            <Box sx={{ mb: 3 }}>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: 2, mb: 2 }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        <AccessTimeIcon sx={{ color: 'text.secondary', fontSize: 20 }} />
                        <Typography variant="body1" color="text.primary">
                            {periodStartFormatted} - {periodEndFormatted}
                        </Typography>
                    </Box>
                    <Box
                        component="span"
                        sx={{
                            width: '4px',
                            height: '4px',
                            borderRadius: '50%',
                            backgroundColor: 'rgba(255, 255, 255, 0.2)',
                        }}
                    />
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        <CheckCircleIcon
                            sx={{
                                color: status === 'active' ? theme.palette.primary.main : 'text.secondary',
                                fontSize: 20,
                            }}
                        />
                        <Chip
                            label={status.toUpperCase()}
                            size="small"
                            sx={{
                                backgroundColor: status === 'active' ? `${theme.palette.primary.main}22` : 'rgba(255, 255, 255, 0.1)',
                                color: status === 'active' ? theme.palette.primary.main : 'text.secondary',
                                borderColor: status === 'active' ? theme.palette.primary.main : 'rgba(255, 255, 255, 0.2)',
                                border: '1px solid',
                            }}
                        />
                    </Box>
                </Box>
                {cancel_at_period_end ? (
                    <Typography variant="body2" color="error">
                        This subscription will be cancelled at the end of the current period.
                    </Typography>
                ) : daysLeft < 40 ? (
                    <Typography variant="body2" color="text.secondary">
                        Your subscription will be renewed automatically in {daysLeft} days
                    </Typography>
                ) : null}
            </Box>

            {!subscription.is_trial && (
                <Button
                    variant="outlined"
                    onClick={manageSubscription}
                    disabled={isLoadingManageSubscription}
                    startIcon={isLoadingManageSubscription ? <CircularProgress size={20} /> : null}
                    endIcon={!isLoadingManageSubscription && <OpenInNewIcon />}
                    sx={{
                        borderColor: 'rgba(255, 255, 255, 0.1)',
                        color: 'text.primary',
                        px: 3,
                        py: 1,
                        minWidth: 220,
                        '&:hover': {
                            borderColor: 'rgba(255, 255, 255, 0.2)',
                            backgroundColor: 'rgba(255, 255, 255, 0.05)',
                            transform: 'translateY(-1px)',
                        },
                        transition: 'all 0.2s ease-in-out',
                    }}
                >
                    {isLoadingManageSubscription ? 'REDIRECTING...' : 'MANAGE SUBSCRIPTION'}
                </Button>
            )}
        </Box>
    );
};

export default ManageSubscription;
