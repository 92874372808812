import React from 'react';
import { Helmet } from 'react-helmet-async';

interface SEOProps {
    title?: string;
    description?: string;
    keywords?: string;
    image?: string;
    url?: string;
    type?: string;
}

const SEO: React.FC<SEOProps> = ({
    title = 'SonaScript - Voice to Text Transcription',
    description = 'Transform your voice into text instantly with SonaScript. Experience unmatched flexibility with offline privacy or cloud precision, AI-powered editing, and seamless dictation anywhere on your system. Perfect for professionals, writers, and anyone who prefers speaking over typing.',
    keywords = 'voice to text, transcription, dictation, speech recognition, Mac app, AI transcription, offline transcription',
    image = '/logo512.png',
    url = 'https://sonascript.com',
    type = 'website',
}) => {
    const siteTitle = title.includes('SonaScript') ? title : `${title} | SonaScript`;
    const fullImageUrl = `${url}${image}`;

    // Structured data for better SEO
    const structuredData = {
        '@context': 'https://schema.org',
        '@type': 'SoftwareApplication',
        name: 'SonaScript',
        applicationCategory: 'Productivity Application',
        operatingSystem: 'macOS',
        description: description,
        downloadUrl: 'https://sonascript.com/download',
        offers: {
            '@type': 'AggregateOffer',
            priceCurrency: 'USD',
            lowPrice: 0,
            highPrice: 5.99,
            offerCount: 3,
            offers: [
                {
                    '@type': 'Offer',
                    name: 'Free Trial',
                    price: 0,
                    priceCurrency: 'USD',
                    description: 'Free trial with core features',
                    availability: 'https://schema.org/InStock',
                },
                {
                    '@type': 'Offer',
                    name: 'Coffee Subscription (USD)',
                    price: 5.99,
                    priceCurrency: 'USD',
                    description: 'Full access with all premium features',
                    availability: 'https://schema.org/InStock',
                },
                {
                    '@type': 'Offer',
                    name: 'Coffee Subscription (EUR)',
                    price: 4.99,
                    priceCurrency: 'EUR',
                    description: 'Full access with all premium features',
                    availability: 'https://schema.org/InStock',
                },
            ],
        },
        image: fullImageUrl,
        url: url,
        logo: fullImageUrl,
    };

    return (
        <Helmet>
            {/* Basic meta tags */}
            <title>{siteTitle}</title>
            <meta name="description" content={description} />
            <meta name="keywords" content={keywords} />

            {/* Open Graph meta tags for social sharing */}
            <meta property="og:title" content={siteTitle} />
            <meta property="og:description" content={description} />
            <meta property="og:image" content={fullImageUrl} />
            <meta property="og:image:width" content="512" />
            <meta property="og:image:height" content="512" />
            <meta property="og:url" content={url} />
            <meta property="og:type" content={type} />

            {/* LinkedIn specific meta tags */}
            <meta property="og:image:secure_url" content={fullImageUrl} />
            <meta property="og:image:type" content="image/png" />
            <meta property="og:site_name" content="SonaScript" />

            {/* Twitter Card meta tags */}
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:title" content={siteTitle} />
            <meta name="twitter:description" content={description} />
            <meta name="twitter:image" content={fullImageUrl} />
            <meta name="twitter:image:alt" content="SonaScript Logo" />

            {/* Additional SEO meta tags */}
            <meta name="robots" content="index, follow" />
            <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            <meta httpEquiv="Content-Type" content="text/html; charset=utf-8" />
            <link rel="canonical" href={url} />

            {/* Favicon and app icons */}
            <link rel="icon" href="/favicon.ico" sizes="any" />
            <link rel="icon" href="/logo512.png" type="image/png" />
            <link rel="apple-touch-icon" href="/logo192.png" />

            {/* Multiple sizes for apple-touch-icon */}
            <link rel="apple-touch-icon" sizes="192x192" href="/logo192.png" />
            <link rel="apple-touch-icon" sizes="512x512" href="/logo512.png" />

            {/* Structured Data */}
            <script type="application/ld+json">{JSON.stringify(structuredData)}</script>
        </Helmet>
    );
};

export default SEO;
