import React from 'react';
import { Button, Tooltip, Fab } from '@mui/material';
import CookieIcon from '@mui/icons-material/Cookie';
import { resetCookieConsent } from './ConsentBanner';

/**
 * A utility component for developers to reset cookie consent and test the banner.
 * Only appears in development mode.
 */
const ConsentDebugger: React.FC = () => {
  // Only render in development environment
  if (process.env.NODE_ENV !== 'development') {
    return null;
  }

  return (
    <Tooltip title="Reset Cookie Consent (Dev Only)" placement="left">
      <Fab
        size="small"
        onClick={resetCookieConsent}
        sx={{
          position: 'fixed',
          bottom: 30,
          right: 30,
          zIndex: 9000,
          bgcolor: '#333',
          '&:hover': {
            bgcolor: '#555',
          }
        }}
      >
        <CookieIcon />
      </Fab>
    </Tooltip>
  );
};

export default ConsentDebugger; 