import { useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

export const useScrollToSection = () => {
    const location = useLocation();
    const navigate = useNavigate();

    const scrollToSection = useCallback((sectionId: string) => {
        // If we're not on the home page, first navigate there
        if (location.pathname !== '/') {
            navigate('/', { state: { scrollTo: sectionId } });
            return;
        }

        // Remove the '#' from the section ID
        const targetId = sectionId.replace('#', '');
        const element = document.getElementById(targetId);
        
        if (element) {
            // Add a small delay to ensure smooth scrolling after any re-renders
            setTimeout(() => {
                const headerOffset = 64; // MUI's default AppBar height
                const extraOffset = 64; // Additional offset to scroll further down
                const elementPosition = element.getBoundingClientRect().top;
                const offsetPosition = elementPosition + window.pageYOffset;

                // Calculate the scroll position with extra offset
                const scrollPosition = offsetPosition - headerOffset + extraOffset;

                window.scrollTo({
                    top: scrollPosition,
                    behavior: 'smooth'
                });
            }, 100);
        }
    }, [location.pathname, navigate]);

    return scrollToSection;
}; 