import React from 'react';
import { Box, Typography, Container, useTheme } from '@mui/material';
import SubscriptionsList from './SubscriptionsList';
import CoffeeIcon from '@mui/icons-material/Coffee';

const PricingSection: React.FC = () => {
    const theme = useTheme();

    return (
        <Box 
            id="pricing"
            sx={{ 
                minHeight: 'calc(100vh - 64px)',
                display: 'flex',
                flexDirection: 'column',
                position: 'relative',
                py: { xs: 8, md: 12 },
                background: `linear-gradient(165deg, 
                    ${theme.palette.background.paper} 0%,
                    ${theme.palette.background.default} 50%,
                    ${theme.palette.background.paper} 100%
                )`,
                '&::before': {
                    content: '""',
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    height: '200px',
                    background: `linear-gradient(180deg, 
                        ${theme.palette.background.default} 0%, 
                        transparent 100%
                    )`,
                    opacity: 0.4,
                    pointerEvents: 'none',
                },
                '&::after': {
                    content: '""',
                    position: 'absolute',
                    bottom: 0,
                    left: 0,
                    right: 0,
                    height: '200px',
                    background: `linear-gradient(0deg, 
                        ${theme.palette.background.default} 0%, 
                        transparent 100%
                    )`,
                    opacity: 0.4,
                    pointerEvents: 'none',
                }
            }}
        >
            <Container maxWidth="lg">
                <Box 
                    sx={{ 
                        flex: 1,
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        position: 'relative',
                        zIndex: 1
                    }}
                >
                    <Box sx={{ textAlign: 'center', mb: { xs: 6, md: 8 } }}>
                        <Box 
                            sx={{ 
                                display: 'inline-flex',
                                alignItems: 'center',
                                gap: 2,
                                mb: 2,
                                mt: 4   

                            }}
                        >
                            <CoffeeIcon 
                                sx={{ 
                                    fontSize: 40,
                                    color: theme.palette.primary.main
                                }} 
                            />
                            <Typography 
                                variant="h3" 
                                component="h2" 
                                sx={{
                                    fontWeight: 'bold',
                                    background: `linear-gradient(45deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
                                    WebkitBackgroundClip: 'text',
                                    WebkitTextFillColor: 'transparent',
                                }}
                            >
                                Choose the Perfect Plan
                            </Typography>
                        </Box>
                        <Typography 
                            variant="h6" 
                            color="text.secondary" 
                            sx={{ 
                                maxWidth: '800px',
                                mx: 'auto',
                                px: 3,
                                mb: 3
                            }}
                        >
                            Start with our free trial and unlock premium features when you're ready to take your productivity to the next level
                        </Typography>
                        {/* <Typography
                            variant="body1"
                            color="text.secondary"
                            sx={{
                                maxWidth: '600px',
                                mx: 'auto',
                                px: 3,
                                opacity: 0.8
                            }}
                        >
                            All plans include a 72-hour full-featured trial. No credit card required to start.
                        </Typography> */}
                    </Box>

                    <Box 
                        sx={{ 
                            position: 'relative',
                            '&::before': {
                                content: '""',
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                                width: '200%',
                                height: '200%',
                                background: `radial-gradient(ellipse at center, 
                                    ${theme.palette.primary.main}11 0%, 
                                    ${theme.palette.secondary.main}11 25%, 
                                    transparent 70%
                                )`,
                                opacity: 0.5,
                                zIndex: -1
                            }
                        }}
                    >
                        <SubscriptionsList />
                    </Box>

                    <Box 
                        sx={{ 
                            mt: { xs: 6, md: 8 },
                            textAlign: 'center'
                        }}
                    >
                        <Typography
                            variant="body2"
                            color="text.secondary"
                            sx={{
                                maxWidth: '500px',
                                mx: 'auto',
                                px: 3,
                                opacity: 0.7
                            }}
                        >
                            Questions about our plans? <br />
                            Contact us at sonascript@gmail.com
                        </Typography>
                    </Box>
                </Box>
            </Container>
        </Box>
    );
};

export default PricingSection; 