import { ThemeOptions } from '@mui/material/styles/createTheme'

// Utility function for Safari-specific font weights
const getFontWeight = (defaultWeight: number): number => {
    // Check if the browser is Safari
    const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent.toLowerCase());
    if (!isSafari) return defaultWeight;
    
    // Make Safari font weights thinner
    const weightMap: { [key: number]: number } = {
        700: 500, // bold -> medium
        600: 400, // semibold -> regular
        500: 300, // medium -> light
        400: 300, // regular -> light
    };
    return weightMap[defaultWeight] || defaultWeight;
};

const darkThemeOptions: ThemeOptions = {
    palette: {
        mode: 'dark',
        primary: {
            main: '#0f0',
        },
        background: {
            default: '#333135',
            paper: '#212121',
        },
        secondary: {
            main: '#ffffff',
        },
    },
    typography: {
        fontFamily: 'Ubuntu Mono, Open Sans',
        h1: { 
            fontFamily: 'Ubuntu Mono',
            fontWeight: getFontWeight(700)
        },
        h2: { 
            fontFamily: 'Ubuntu Mono',
            fontWeight: getFontWeight(700)
        },
        h3: { 
            fontFamily: 'Ubuntu Mono',
            fontWeight: getFontWeight(700)
        },
        h4: { 
            fontFamily: 'Ubuntu Mono',
            fontWeight: getFontWeight(600)
        },
        h5: { 
            fontFamily: 'Ubuntu Mono',
            fontWeight: getFontWeight(600)
        },
        h6: { 
            fontFamily: 'Ubuntu Mono',
            fontWeight: getFontWeight(600)
        },
        subtitle1: { 
            fontFamily: 'Ubuntu Mono',
            fontWeight: getFontWeight(500)
        },
        subtitle2: { 
            fontFamily: 'Ubuntu Mono',
            fontWeight: getFontWeight(500)
        },
        body1: {
            fontFamily: 'Ubuntu Mono',
            fontWeight: getFontWeight(400)
        },
        body2: {
            fontFamily: 'Ubuntu Mono',
            fontWeight: getFontWeight(400)
        },
        button: {
            fontFamily: 'Ubuntu Mono',
            fontWeight: getFontWeight(700),
            lineHeight: 1.75,
        },
        overline: { 
            fontFamily: 'Ubuntu Mono',
            fontWeight: getFontWeight(400)
        }
    },
    shape: {
        borderRadius: 4,
    },
    spacing: 8,
    components: {
        MuiCssBaseline: {
            styleOverrides: {
                'input:-webkit-autofill': {
                    WebkitBoxShadow: `0 0 0 1000px ${'#333135'} inset`,
                    transitionDelay: '9999s',
                },
                'input:-webkit-autofill:hover': {
                    WebkitBoxShadow: `0 0 0 1000px ${'#333135'} inset`,
                },
                'input:-webkit-autofill:focus': {
                    WebkitBoxShadow: `0 0 0 1000px ${'#333135'} inset`,
                },
                'input:-webkit-autofill:active': {
                    WebkitBoxShadow: `0 0 0 1000px ${'#333135'} inset`,
                },
                // Global styles for Safari
                '@media not all and (min-resolution:.001dpcm)': {
                    '@supports (-webkit-appearance:none)': {
                        '.MuiTypography-root': {
                            fontWeight: getFontWeight(700),
                        },
                        '.MuiButton-root': {
                            fontWeight: getFontWeight(700),
                        }
                    }
                }
            }
        },
        // Override for all buttons
        MuiButton: {
            styleOverrides: {
                root: {
                    fontWeight: getFontWeight(700),
                    '&.MuiButton-contained': {
                        fontWeight: getFontWeight(700),
                    },
                    '&.MuiButton-outlined': {
                        fontWeight: getFontWeight(700),
                    },
                    '&.MuiButton-text': {
                        fontWeight: getFontWeight(700),
                    },
                },
            },
        },
        // Override for all typography
        MuiTypography: {
            styleOverrides: {
                root: {
                    '&.MuiTypography-button': {
                        fontWeight: getFontWeight(700),
                    },
                },
            },
        },
        // Override for links
        MuiLink: {
            styleOverrides: {
                root: {
                    fontWeight: getFontWeight(700),
                },
            },
        },
    },
};

export default darkThemeOptions;
