// SubscriptionCard.tsx
import React, { useRef } from 'react';
import { Paper, Box, Typography, Button, Stack, useTheme } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { useTiltEffect } from '../hooks/useTiltEffect';
import { useIsSafari } from '../hooks/useIsSafari';
import { Plan } from '../types/subscription';
import { spinAnimation, glowAnimation, movingGradientAnimation } from './animations';

const getShadows = (isSafari: boolean) => ({
    // Primary shadows for large text (titles, prices)
    PRIMARY: {
        LAYER_1: isSafari 
            ? '0px 1px 2px rgba(0,0,0,0.4)'
            : '0px 2px 4px rgba(0,0,0,0.8)',
        LAYER_2: isSafari
            ? '0px 2px 4px rgba(0,0,0,0.2)'
            : '0px 4px 8px rgba(0,0,0,0.4)',
        COMBINED: isSafari
            ? '0px 1px 2px rgba(0,0,0,0.4), 0px 2px 4px rgba(0,0,0,0.2)'
            : '0px 2px 4px rgba(0,0,0,0.8), 0px 4px 8px rgba(0,0,0,0.4)'
    },
    // Secondary shadows for body text
    SECONDARY: {
        NORMAL: isSafari
            ? '0px 1px 1px rgba(0,0,0,0.4)'
            : '0px 1px 2px rgba(0,0,0,0.8)'
    },
    // Icon shadows
    ICON: {
        NORMAL: isSafari
            ? 'drop-shadow(0 2px 3px rgba(0, 0, 0, 0.2))'
            : 'drop-shadow(0 3px 6px rgba(0, 0, 0, 0.4))',
        HIGHLIGHTED: isSafari
            ? 'drop-shadow(0 3px 3px rgba(0,0,0,0.3))'
            : 'drop-shadow(0 6px 6px rgba(0,0,0,0.6))'
    }
});

// Utility function for Safari-specific font weights
const getFontWeight = (isSafari: boolean, defaultWeight: number) => {
    if (!isSafari) return defaultWeight;
    // Make Safari font weights thinner
    const weightMap: { [key: number]: number } = {
        700: 500, // bold -> medium
        600: 400, // semibold -> regular
        500: 300, // medium -> light
        400: 300, // regular -> light
    };
    return weightMap[defaultWeight] || defaultWeight;
};

interface SubscriptionCardProps {
    plan: Plan;
    onSubscribe: () => void;
    onNavigate: (path: string) => void;
    loadingCheckout: boolean;
}

const SubscriptionCard: React.FC<SubscriptionCardProps> = ({
    plan,
    onSubscribe,
    onNavigate,
    loadingCheckout
}) => {
    const theme = useTheme();
    const isSafari = useIsSafari();
    const SHADOWS = getShadows(isSafari);
    const cardRef = useRef<HTMLDivElement>(null);
    const tiltValues = useTiltEffect(cardRef, {
        perspective: 1500,    // Increased for more subtle depth
        scale: 1.01,         // Keeping subtle scale
        max: 5,              // Reduced from 10 to 5 degrees for subtler tilt
        speed: 100,          // Reduced from 2000 to 100ms for near real-time response
    });

    return (
        <Paper
            ref={cardRef}
            elevation={plan.highlighted ? 8 : 3}
            sx={{
                position: 'relative',
                p: 4,
                height: 580,
                display: 'flex',
                flexDirection: 'column',
                background: plan.highlighted
                    ? `linear-gradient(135deg, 
                        ${theme.palette.background.paper} 0%,
                        ${theme.palette.background.default} 100%)`
                    : theme.palette.background.paper,
                borderRadius: 4,
                border: '1px solid',
                borderColor: plan.highlighted ? 'rgba(255, 255, 255, 0.1)' : 'rgba(255, 255, 255, 0.05)',
                transform: tiltValues.transform,
                transition: tiltValues.transition,
                '&::before': plan.highlighted ? {
                    content: '""',
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    borderRadius: 4,
                    padding: '1px',
                    background: `linear-gradient(45deg, ${theme.palette.primary.main}66, ${theme.palette.secondary.main}66)`,
                    mask: 'linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)',
                    maskComposite: 'exclude',
                    pointerEvents: 'none',
                } : undefined,
                '&::after': plan.highlighted ? {
                    content: '""',
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    borderRadius: 4,
                    background: `radial-gradient(circle at ${tiltValues.gradientPosition}, 
                        rgba(255, 182, 255, 0.6) 0%,
                        rgba(255, 255, 140, 0.5) 15%,
                        rgba(140, 255, 255, 0.4) 30%,
                        rgba(148, 180, 255, 0.3) 45%,
                        rgba(180, 130, 255, 0.2) 60%,
                        rgba(255, 110, 180, 0.1) 75%,
                        rgba(140, 140, 255, 0) 100%)`,
                    backgroundSize: '200% 200%',
                    backgroundPosition: tiltValues.gradientPosition,
                    transition: 'all 100ms ease-out',
                    mixBlendMode: 'screen',
                    opacity: 0.2,
                    zIndex: -1,
                    filter: 'blur(0.5px)',
                } : undefined,
            }}
        >
            <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                {/* Plan Header */}
                <Box>
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: 1,
                            mb: 2,
                            color: plan.highlighted ? 'primary.main' : 'text.primary',
                            ...(plan.highlighted && {
                                textShadow: SHADOWS.PRIMARY.LAYER_1,
                            }),
                        }}
                    >
                        <Box
                            sx={
                                plan.isSpecialOffer || plan.title.includes('Coffee')
                                    ? {
                                          animation: `${spinAnimation} 8s cubic-bezier(0.4, 0, 0.2, 1) infinite`,
                                          display: 'flex',
                                          alignItems: 'center',
                                          transformOrigin: 'center center',
                                          filter: plan.highlighted ? SHADOWS.ICON.HIGHLIGHTED : 'none',
                                      }
                                    : undefined
                            }
                        >
                            {plan.icon}
                        </Box>
                        <Typography
                            pl={1}
                            variant="h6"
                            component="h3"
                            sx={{
                                fontWeight: getFontWeight(isSafari, 700),
                                ...(plan.isSpecialOffer
                                    ? {
                                          fontSize: '1.5rem',
                                          animation: `${glowAnimation(theme, isSafari)} 4s ease-in-out infinite`,
                                          background: `linear-gradient(45deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
                                          WebkitBackgroundClip: 'text',
                                          WebkitTextFillColor: 'transparent',
                                          position: 'relative',
                                          zIndex: 1,
                                          textShadow: SHADOWS.PRIMARY.COMBINED,
                                          '&::after': {
                                              content: '""',
                                              position: 'absolute',
                                              top: -4,
                                              left: -4,
                                              right: -4,
                                              bottom: -4,
                                              background: `radial-gradient(circle, ${theme.palette.primary.main}40 0%, transparent 70%)`,
                                              zIndex: -1,
                                              opacity: 0.5,
                                              animation: `${glowAnimation(theme, isSafari)} 4s ease-in-out infinite`,
                                          },
                                      }
                                    : plan.highlighted ? {
                                          textShadow: SHADOWS.PRIMARY.LAYER_1,
                                      }
                                    : undefined)
                            }}
                        >
                            {plan.title}
                        </Typography>
                    </Box>
                    <Typography 
                        variant="body2" 
                        color="text.secondary" 
                        sx={{ 
                            minHeight: 48,
                            fontWeight: getFontWeight(isSafari, 400),
                            ...(plan.highlighted && {
                                textShadow: SHADOWS.SECONDARY.NORMAL,
                            }),
                        }}
                    >
                        {plan.description}
                    </Typography>
                </Box>

                {/* Price */}
                <Box sx={{ mt: 3 }}>
                    {plan.isSpecialOffer ? (
                        <Box>
                            <Typography 
                                variant="h4" 
                                component="div"
                                sx={{ 
                                    mb: 1,
                                    fontWeight: getFontWeight(isSafari, 700),
                                    color: 'primary.main',
                                    ...(plan.highlighted && {
                                        textShadow: SHADOWS.PRIMARY.COMBINED,
                                    }),
                                }}
                            >
                                First Month Free
                            </Typography>
                            <Typography 
                                variant="body1" 
                                color="text.secondary"
                                sx={{
                                    fontWeight: getFontWeight(isSafari, 400),
                                    ...(plan.highlighted ? {
                                        textShadow: SHADOWS.SECONDARY.NORMAL,
                                    } : undefined)
                                }}
                            >
                                Then {plan.price}
                                {plan.period}
                            </Typography>
                        </Box>
                    ) : (
                        <Typography
                            variant="h3"
                            component="div"
                            sx={{
                                display: 'flex',
                                alignItems: 'baseline',
                                gap: 1,
                                fontWeight: getFontWeight(isSafari, 700),
                                color: plan.highlighted ? 'primary.main' : 'text.primary',
                                ...(plan.highlighted && {
                                    textShadow: SHADOWS.PRIMARY.COMBINED,
                                }),
                            }}
                        >
                            {plan.price}
                            <Typography 
                                component="span" 
                                variant="h6" 
                                color="text.secondary"
                                sx={{
                                    fontWeight: getFontWeight(isSafari, 400),
                                    ...(plan.highlighted ? {
                                        textShadow: SHADOWS.SECONDARY.NORMAL,
                                    } : undefined)
                                }}
                            >
                                {plan.period}
                            </Typography>
                        </Typography>
                    )}
                </Box>

                {/* Features */}
                <Stack spacing={2} sx={{ mt: 3, mb: 'auto' }}>
                    {plan.features.map((feature, idx) => (
                        <Box
                            key={idx}
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: 1.5,
                            }}
                        >
                            <CheckCircleOutlineIcon
                                sx={{
                                    color: feature.highlight ? 'primary.main' : 'text.secondary',
                                    fontSize: 20,
                                    flexShrink: 0,
                                    ...(plan.highlighted && {
                                        filter: SHADOWS.ICON.NORMAL,
                                    }),
                                }}
                            />
                            <Typography
                                variant="body2"
                                sx={{
                                    color: feature.highlight ? 'text.primary' : 'text.secondary',
                                    fontWeight: getFontWeight(isSafari, feature.highlight ? 500 : 400),
                                    ...(plan.highlighted && {
                                        textShadow: SHADOWS.SECONDARY.NORMAL,
                                    }),
                                }}
                            >
                                {feature.text}
                            </Typography>
                        </Box>
                    ))}
                </Stack>

                {/* CTA Button */}
                <Box sx={{ mt: 4 }}>
                    <Button
                        onClick={plan.highlighted ? onSubscribe : () => onNavigate(plan.buttonLink)}
                        variant={plan.highlighted ? 'contained' : 'outlined'}
                        size="large"
                        fullWidth
                        disabled={loadingCheckout}
                        sx={{
                            py: 1.5,
                            fontWeight: getFontWeight(isSafari, 700),
                            ...(plan.highlighted && {
                                background: `linear-gradient(45deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
                                '&:hover': {
                                    background: `linear-gradient(45deg, ${theme.palette.primary.dark}, ${theme.palette.secondary.dark})`,
                                },
                            }),
                        }}
                    >
                        {plan.buttonText}
                    </Button>
                </Box>
            </Box>
        </Paper>
    );
};

export default SubscriptionCard;
