import React, { useContext, useEffect, useState } from 'react';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
import { 
    AppBar, 
    Toolbar, 
    Button, 
    Box, 
    useTheme, 
    useMediaQuery, 
    Container, 
    IconButton,
    Menu,
    MenuItem,
    Divider
} from '@mui/material';
import { AuthContext } from '../context/AuthProvider';
import { useScrollToSection } from '../hooks/useScrollToSection';
import MenuIcon from '@mui/icons-material/Menu';

const NavBar: React.FC = () => {
    const authContext = useContext(AuthContext);
    const { logOut, user } = authContext || {};
    const theme = useTheme();
    const location = useLocation();
    const navigate = useNavigate();
    const isMobile = useMediaQuery('(max-width:820px)');
    const scrollToSection = useScrollToSection();
    const [isScrolled, setIsScrolled] = useState(false);
    const [mobileMenuAnchor, setMobileMenuAnchor] = useState<null | HTMLElement>(null);

    useEffect(() => {
        const handleScroll = () => {
            setIsScrolled(window.scrollY > 20);
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    const handleNavClick = (e: React.MouseEvent<HTMLAnchorElement>, sectionId: string) => {
        e.preventDefault();
        scrollToSection(sectionId);
        handleMobileMenuClose();
    };

    const handleMobileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
        setMobileMenuAnchor(event.currentTarget);
    };

    const handleMobileMenuClose = () => {
        setMobileMenuAnchor(null);
    };

    const handleMobileAction = (action: () => void) => {
        action();
        handleMobileMenuClose();
    };

    const handleLogoClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault();
        if (location.pathname === '/') {
            window.scrollTo({ top: 0, behavior: 'smooth' });
        } else {
            navigate('/');
            setTimeout(() => {
                window.scrollTo({ top: 0, behavior: 'auto' });
            }, 0);
        }
    };

    return (
        <Box sx={{ flexGrow: 1 }}>
            <AppBar
                position="fixed"
                sx={{
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                    backgroundColor: 'transparent',
                    boxShadow: 'none',
                    transition: 'all 0.5s ease-in-out',
                    mt: 0,
                }}
                elevation={0}
            >
                <Container maxWidth="lg">
                    <Box
                        sx={{
                            mx: { xs: 2, md: 3 },
                            mt: { xs: 1, md: 1.5 },
                            mb: { xs: 1, md: 1.5 },
                            backgroundColor: isScrolled ? 'rgba(33, 33, 33, 0.65)' : 'transparent',
                            borderRadius: 2,
                            transition: 'all 0.3s ease-in-out',
                            boxShadow: isScrolled ? '0 8px 32px 0 rgba(0, 0, 0, 0.1)' : 'none',
                            backdropFilter: isScrolled ? 'blur(10px)' : 'none',
                            border: isScrolled ? '1px solid rgba(255, 255, 255, 0.05)' : 'none',
                        }}
                    >
                        <Toolbar 
                            sx={{ 
                                display: 'flex', 
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                minHeight: { xs: '48px', md: '56px' },
                                height: { xs: '48px', md: '56px' },
                                py: 0,
                                px: { xs: 1.5, md: 2 }
                            }}
                        >
                            {/* Left section with logo and primary navigation */}
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                <Button
                                    component={RouterLink}
                                    to="/"
                                    onClick={handleLogoClick}
                                    sx={{
                                        fontSize: { xs: '1.1rem', md: '1.2rem' },
                                        fontWeight: 'bold',
                                        color: 'primary.main',
                                        mr: 3,
                                        px: 2,
                                        height: { xs: '36px', md: '40px' },
                                        minHeight: 0
                                    }}
                                >
                                    SonaScript
                                </Button>
                                {!isMobile && (
                                    <>
                                        <Button
                                            component="a"
                                            href="#features"
                                            onClick={(e) => handleNavClick(e, '#features')}
                                            sx={{ 
                                                mr: 2, 
                                                color: 'text.primary',
                                                px: 2,
                                                height: { xs: '36px', md: '40px' },
                                                minHeight: 0,
                                                '&:hover': {
                                                    backgroundColor: 'rgba(255, 255, 255, 0.05)'
                                                }
                                            }}
                                        >
                                            Features
                                        </Button>
                                        <Button
                                            component="a"
                                            href="#pricing"
                                            onClick={(e) => handleNavClick(e, '#pricing')}
                                            sx={{ 
                                                color: 'text.primary',
                                                px: 2,
                                                height: { xs: '36px', md: '40px' },
                                                minHeight: 0,
                                                '&:hover': {
                                                    backgroundColor: 'rgba(255, 255, 255, 0.05)'
                                                }
                                            }}
                                        >
                                            Pricing
                                        </Button>
                                    </>
                                )}
                            </Box>

                            {/* Right section with CTAs */}
                            {isMobile ? (
                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                    <IconButton
                                        onClick={handleMobileMenuOpen}
                                        sx={{ 
                                            color: 'text.primary',
                                            padding: '8px',
                                            height: '36px',
                                            width: '36px'
                                        }}
                                    >
                                        <MenuIcon />
                                    </IconButton>
                                    <Menu
                                        anchorEl={mobileMenuAnchor}
                                        open={Boolean(mobileMenuAnchor)}
                                        onClose={handleMobileMenuClose}
                                        PaperProps={{
                                            sx: {
                                                backgroundColor: 'rgba(33, 33, 33, 0.95)',
                                                backdropFilter: 'blur(10px)',
                                                border: '1px solid rgba(255, 255, 255, 0.05)',
                                                mt: 1,
                                                minWidth: 200
                                            }
                                        }}
                                        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                                        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                                    >
                                        {/* Mobile Menu Items */}
                                        <MenuItem
                                            onClick={(e) => handleNavClick(e as any, '#features')}
                                            sx={{ color: 'text.primary' }}
                                        >
                                            Features
                                        </MenuItem>
                                        <MenuItem
                                            onClick={(e) => handleNavClick(e as any, '#pricing')}
                                            sx={{ color: 'text.primary' }}
                                        >
                                            Pricing
                                        </MenuItem>
                                        <Divider sx={{ my: 1, borderColor: 'rgba(255, 255, 255, 0.1)' }} />
                                        {user && logOut ? (
                                            <>
                                                <MenuItem
                                                    component={RouterLink}
                                                    to="/profile"
                                                    onClick={handleMobileMenuClose}
                                                    sx={{ color: 'text.primary' }}
                                                >
                                                    My Profile
                                                </MenuItem>
                                                <MenuItem
                                                    onClick={() => handleMobileAction(logOut)}
                                                    sx={{ color: 'text.primary' }}
                                                >
                                                    Logout
                                                </MenuItem>
                                            </>
                                        ) : (
                                            <MenuItem
                                                component={RouterLink}
                                                to="/signup"
                                                onClick={handleMobileMenuClose}
                                                sx={{ color: 'text.primary' }}
                                            >
                                                Sign In
                                            </MenuItem>
                                        )}
                                        <MenuItem
                                            component={RouterLink}
                                            to="/download"
                                            onClick={handleMobileMenuClose}
                                            sx={{ 
                                                color: 'primary.main',
                                                fontWeight: 'bold'
                                            }}
                                        >
                                            Download Free
                                        </MenuItem>
                                    </Menu>
                                </Box>
                            ) : (
                                <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                                    {user && logOut ? (
                                        <>
                                            <Button
                                                component={RouterLink}
                                                to="/profile"
                                                variant="text"
                                                sx={{ 
                                                    color: 'text.primary',
                                                    px: 2,
                                                    height: { xs: '36px', md: '40px' },
                                                    minHeight: 0,
                                                    transition: 'all 0.2s ease-in-out',
                                                    '&:hover': {
                                                        backgroundColor: 'rgba(255, 255, 255, 0.05)',
                                                        transform: 'translateY(-1px)'
                                                    }
                                                }}
                                            >
                                                My Profile
                                            </Button>
                                            <Button
                                                onClick={logOut}
                                                variant="text"
                                                sx={{ 
                                                    color: 'text.primary',
                                                    px: 2,
                                                    height: { xs: '36px', md: '40px' },
                                                    minHeight: 0,
                                                    transition: 'all 0.2s ease-in-out',
                                                    '&:hover': {
                                                        backgroundColor: 'rgba(255, 255, 255, 0.05)',
                                                        transform: 'translateY(-1px)'
                                                    }
                                                }}
                                            >
                                                Logout
                                            </Button>
                                        </>
                                    ) : (
                                        <Button
                                            component={RouterLink}
                                            to="/signup"
                                            variant="text"
                                            sx={{ 
                                                color: 'text.primary',
                                                px: 2,
                                                height: { xs: '36px', md: '40px' },
                                                minHeight: 0,
                                                transition: 'all 0.2s ease-in-out',
                                                '&:hover': {
                                                    backgroundColor: 'rgba(255, 255, 255, 0.05)',
                                                    transform: 'translateY(-1px)'
                                                }
                                            }}
                                        >
                                            Sign In
                                        </Button>
                                    )}
                                    <Button
                                        component={RouterLink}
                                        to="/download"
                                        variant="contained"
                                        color="primary"
                                        sx={{
                                            fontWeight: 'bold',
                                            px: 3,
                                            height: { xs: '36px', md: '40px' },
                                            minHeight: 0,
                                            transition: 'all 0.2s ease-in-out',
                                            '&:hover': {
                                                transform: 'translateY(-1px)',
                                            },
                                        }}
                                    >
                                        Download Free
                                    </Button>
                                </Box>
                            )}
                        </Toolbar>
                    </Box>
                </Container>
            </AppBar>
            <Box sx={{ height: { xs: '64px', md: '80px' } }} />
        </Box>
    );
};

export default NavBar;
