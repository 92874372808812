import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Box, Link, Typography, Container, useTheme, Stack } from '@mui/material';
import CoffeeIcon from '@mui/icons-material/Coffee';

const Footer: React.FC = () => {
    const theme = useTheme();

    return (
        <Box
            component="footer"
            sx={{
                py: 3,
                position: 'relative',
                backgroundColor: 'rgba(33, 33, 33, 0.65)',
                backdropFilter: 'blur(10px)',
                borderTop: '1px solid rgba(255, 255, 255, 0.05)',
                zIndex: 1,
                marginTop: 'auto',
                width: '100%'
            }}
        >
            <Box
                sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    backdropFilter: 'blur(10px)',
                    zIndex: -1
                }}
            />
            <Container maxWidth="lg">
                <Stack
                    direction={{ xs: 'column', sm: 'row' }}
                    spacing={{ xs: 2, sm: 3 }}
                    alignItems="center"
                    justifyContent="space-between"
                >
                    {/* Logo and Copyright */}
                    <Box 
                        sx={{ 
                            display: 'flex',
                            alignItems: 'center',
                            gap: 1
                        }}
                    >
                        <CoffeeIcon 
                            sx={{ 
                                fontSize: 20,
                                color: theme.palette.primary.main
                            }} 
                        />
                        <Typography 
                            variant="body2" 
                            color="text.secondary"
                        >
                            © {new Date().getFullYear()} SonaScript
                        </Typography>
                    </Box>

                    {/* Links */}
                    <Stack 
                        direction={{ xs: 'column', sm: 'row' }} 
                        spacing={{ xs: 1.5, sm: 3 }}
                        alignItems="center"
                        divider={
                            <Box 
                                sx={{ 
                                    width: '3px',
                                    height: '3px',
                                    borderRadius: '50%',
                                    bgcolor: 'rgba(255, 255, 255, 0.1)',
                                    display: { xs: 'none', sm: 'block' }
                                }} 
                            />
                        }
                    >
                        <Link 
                            component={RouterLink} 
                            to="/terms" 
                            sx={{ 
                                fontSize: '0.875rem',
                                textDecoration: 'none',
                                color: 'text.secondary',
                                transition: 'color 0.2s',
                                position: 'relative',
                                '&:hover': {
                                    color: theme.palette.primary.main
                                }
                            }}
                        >
                            Terms of Service
                        </Link>
                        <Link 
                            component={RouterLink} 
                            to="/privacy" 
                            sx={{ 
                                fontSize: '0.875rem',
                                textDecoration: 'none',
                                color: 'text.secondary',
                                transition: 'color 0.2s',
                                position: 'relative',
                                '&:hover': {
                                    color: theme.palette.primary.main
                                }
                            }}
                        >
                            Privacy Policy
                        </Link>
                        <Link 
                            href="mailto:sonascript@gmail.com"
                            sx={{ 
                                fontSize: '0.875rem',
                                textDecoration: 'none',
                                color: 'text.secondary',
                                transition: 'color 0.2s',
                                position: 'relative',
                                '&:hover': {
                                    color: theme.palette.primary.main
                                }
                            }}
                        >
                            Contact
                        </Link>
                    </Stack>
                </Stack>
            </Container>
        </Box>
    );
};

export default Footer;
