import React from 'react';
import { Box, Typography, useTheme, Button, Stack } from '@mui/material';
import { SubscriptionResponse, Device } from '../types/types';
import ManageSubscription from './ManageSubscription';
import LaptopMacIcon from '@mui/icons-material/LaptopMac';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

interface SubscriptionProps {
    subscription: SubscriptionResponse;
    onDeleteDevice: (deviceId: string) => Promise<void>;
}

const Subscription: React.FC<SubscriptionProps> = ({ subscription, onDeleteDevice }) => {
    const theme = useTheme();

    if (!subscription || !subscription.subscription_data) {
        return null;
    }

    const { device_count, device_limit, devices } = subscription;

    return (
        <Box sx={{ width: '100%', maxWidth: '800px', mx: 'auto' }}>
            <Stack spacing={6}>
                <ManageSubscription subscription={subscription} />
                
                {devices.length > 0 && (
                    <Box>
                        <Typography 
                            variant="h6" 
                            sx={{ 
                                mb: 3,
                                color: 'text.secondary',
                                textAlign: 'center'
                            }}
                        >
                            Registered Devices {device_count}/{device_limit}
                        </Typography>

                        <Stack 
                            spacing={2}
                            sx={{
                                maxWidth: 600,
                                mx: 'auto'
                            }}
                        >
                            {devices.map((device: Device) => (
                                <Box
                                    key={device.device_id}
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'space-between',
                                        py: 2,
                                        px: 3,
                                        borderRadius: 2,
                                        backgroundColor: 'rgba(255, 255, 255, 0.03)',
                                        border: '1px solid',
                                        borderColor: 'rgba(255, 255, 255, 0.05)',
                                        transition: 'all 0.2s ease-in-out',
                                        '&:hover': {
                                            backgroundColor: 'rgba(255, 255, 255, 0.05)',
                                            borderColor: 'rgba(255, 255, 255, 0.1)',
                                        }
                                    }}
                                >
                                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                                        <LaptopMacIcon 
                                            sx={{ 
                                                color: theme.palette.primary.main,
                                                fontSize: 24
                                            }} 
                                        />
                                        <Typography 
                                            variant="body1"
                                            sx={{
                                                color: 'text.primary',
                                                fontWeight: 500
                                            }}
                                        >
                                            {device.device_name}
                                        </Typography>
                                    </Box>
                                    <Button
                                        onClick={() => onDeleteDevice(device.device_id)}
                                        startIcon={<DeleteOutlineIcon />}
                                        color="error"
                                        sx={{
                                            minWidth: 'auto',
                                            color: theme.palette.error.main,
                                            '&:hover': {
                                                backgroundColor: `${theme.palette.error.main}22`,
                                            }
                                        }}
                                    >
                                        Remove
                                    </Button>
                                </Box>
                            ))}
                        </Stack>
                    </Box>
                )}
            </Stack>
        </Box>
    );
};

export default Subscription;
