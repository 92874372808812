import React, { useContext, useState, useEffect } from 'react';
import { AuthContext } from '../context/AuthProvider';
import { useNavigate, Link } from 'react-router-dom';
import { Button, CircularProgress, Box, Typography, Container, Stack, useTheme } from '@mui/material';
import GoogleIcon from '@mui/icons-material/Google';
import AppleIcon from '@mui/icons-material/Apple';

// Layout constants
const LAYOUT = {
    NAVBAR_HEIGHT: 80, // MUI's default AppBar height
    NAVBAR_MARGIN: 0, // 1 unit of margin
    FOOTER_HEIGHT: 70, // Footer height (py: 3 = 48px) + extra space for content
} as const;

const TOTAL_OFFSET = LAYOUT.NAVBAR_HEIGHT + LAYOUT.NAVBAR_MARGIN + LAYOUT.FOOTER_HEIGHT;

const SignUp: React.FC = () => {
    const theme = useTheme();
    const authContext = useContext(AuthContext);
    const { loading, user, couponCode, signInWithGoogle, signInWithApple } = authContext || {};
    const navigate = useNavigate();
    const [error, setError] = useState<string>('');

    // 🚀 **Add Logo Animation Logic**
    const logoImages = [
        '/icons/icon_000.png',
        '/icons/icon_010.png',
        '/icons/icon_020.png',
        '/icons/icon_030.png',
        '/icons/icon_040.png',
        '/icons/icon_050.png',
        '/icons/icon_060.png',
        '/icons/icon_070.png',
        '/icons/icon_080.png',
        '/icons/icon_090.png',
        '/icons/icon_100.png',
        '/icons/icon_110.png',
        '/icons/icon_120.png',
        '/icons/icon_130.png',
        '/icons/icon_140.png',
        '/icons/icon_150.png',
        '/icons/icon_160.png',
        '/icons/icon_170.png',
        '/icons/icon_180.png',
        '/icons/icon_190.png',
        '/icons/icon_200.png',
        '/icons/icon_210.png',
        '/icons/icon_220.png',
        '/icons/icon_230.png',
        '/icons/icon_240.png',
        '/icons/icon_250.png',
        '/icons/icon_260.png',
        '/icons/icon_270.png',
        '/icons/icon_280.png',
        '/icons/icon_290.png',
    ];
    const [logoIndex, setLogoIndex] = useState(0);

    useEffect(() => {
        const timer = setInterval(() => {
            setLogoIndex((prevIndex) => (prevIndex + 1) % logoImages.length);
        }, 1000); // Change logo every second

        return () => clearInterval(timer); // Cleanup on unmount
    }, []);

    const handleGoogleSignIn = async () => {
        if (signInWithGoogle) {
            try {
                await signInWithGoogle();
                navigate('/profile');
            } catch (error) {
                console.error('Error signing in with Google:', error);
                setError('Failed to sign in with Google. Please try again.');
            }
        }
    };

    const handleAppleSignIn = async () => {
        if (signInWithApple) {
            try {
                await signInWithApple();
                navigate('/profile');
            } catch (error) {
                console.error('Error signing in with Apple:', error);
                setError('Failed to sign in with Apple. Please try again.');
            }
        }
    };

    if (loading) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
                <CircularProgress />
            </Box>
        );
    }

    if (user) {
        navigate(couponCode ? `/profile?coupon=${couponCode}` : '/profile');
        return null;
    }

    return (
        <Box
            sx={{
                height: `calc(100vh - ${TOTAL_OFFSET}px)`,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                background: `linear-gradient(135deg, 
                    ${theme.palette.background.paper} 0%,
                    ${theme.palette.background.default} 100%
                )`,
                position: 'relative',
                '&::before': {
                    content: '""',
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    height: '200px',
                    background: `linear-gradient(180deg, ${theme.palette.background.default} 0%, transparent 100%)`,
                    opacity: 0.4,
                    pointerEvents: 'none',
                },
                '&::after': {
                    content: '""',
                    position: 'absolute',
                    bottom: 0,
                    left: 0,
                    right: 0,
                    height: '200px',
                    background: `linear-gradient(0deg, ${theme.palette.background.default} 0%, transparent 100%)`,
                    opacity: 0.4,
                    pointerEvents: 'none',
                },
            }}
        >
            <Container maxWidth="sm">
                <Stack spacing={4} alignItems="center">
                    {/* Logo */}
                    <Box
                        sx={{
                            width: '100%',
                            maxWidth: 300,
                            mx: 'auto',
                            position: 'relative',
                            '&::after': {
                                content: '""',
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                                width: '150%',
                                height: '150%',
                                background: `radial-gradient(circle, ${theme.palette.primary.main}22 0%, transparent 70%)`,
                                zIndex: -1,
                            },
                        }}
                    >
                        <img src={logoImages[logoIndex]} alt="SonaScript Logo" style={{ width: '100%' }} />
                    </Box>

                    {/* Sign-in Buttons */}
                    <Stack spacing={2} sx={{ width: '100%', maxWidth: 360 }}>
                        <Button
                            onClick={handleGoogleSignIn}
                            variant="outlined"
                            size="large"
                            startIcon={<GoogleIcon />}
                            sx={{
                                py: 1.5,
                                borderColor: 'rgba(255, 255, 255, 0.1)',
                                backgroundColor: 'rgba(255, 255, 255, 0.05)',
                                color: 'text.primary',
                                transition: 'all 0.2s ease-in-out',
                                '&:hover': {
                                    transform: 'translateY(-2px)',
                                    borderColor: 'rgba(255, 255, 255, 0.2)',
                                    backgroundColor: 'rgba(255, 255, 255, 0.1)',
                                },
                            }}
                        >
                            SIGN-IN WITH GOOGLE
                        </Button>
                        <Button
                            onClick={handleAppleSignIn}
                            variant="outlined"
                            size="large"
                            startIcon={<AppleIcon />}
                            sx={{
                                py: 1.5,
                                borderColor: 'rgba(255, 255, 255, 0.1)',
                                backgroundColor: 'rgba(255, 255, 255, 0.05)',
                                color: 'text.primary',
                                transition: 'all 0.2s ease-in-out',
                                '&:hover': {
                                    transform: 'translateY(-2px)',
                                    borderColor: 'rgba(255, 255, 255, 0.2)',
                                    backgroundColor: 'rgba(255, 255, 255, 0.1)',
                                },
                            }}
                        >
                            SIGN-IN WITH APPLE
                        </Button>
                    </Stack>

                    {/* Terms and Privacy */}
                    <Typography
                        variant="body2"
                        color="text.secondary"
                        align="center"
                        sx={{
                            fontSize: '0.75rem',
                            opacity: 0.8,
                            maxWidth: 360,
                            mx: 'auto',
                        }}
                    >
                        By proceeding, you agree to our{' '}
                        <Link
                            to="/terms"
                            style={{
                                color: theme.palette.primary.main,
                                textDecoration: 'none',
                            }}
                        >
                            Terms of Service
                        </Link>{' '}
                        and{' '}
                        <Link
                            to="/privacy"
                            style={{
                                color: theme.palette.primary.main,
                                textDecoration: 'none',
                            }}
                        >
                            Privacy Policy
                        </Link>
                    </Typography>
                </Stack>
            </Container>
        </Box>
    );
};

export default SignUp;
