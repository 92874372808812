import { keyframes } from '@mui/material';

export const spinAnimation = keyframes`
    0% {
        transform: rotate(0deg);
    }
    10% {
        transform: rotate(385deg);
    }
    15% {
        transform: rotate(345deg);
    }
    20% {
        transform: rotate(375deg);
    }
    25% {
        transform: rotate(355deg);
    }
    30% {
        transform: rotate(365deg);
    }
    35% {
        transform: rotate(355deg);
    }
    50% {
        transform: rotate(360deg);
    }
    100% {
        transform: rotate(360deg);
    }
`;

// Define the glow animation with browser-specific values
export const glowAnimation = (theme: any, isSafari: boolean) => keyframes`
    0% {
        text-shadow: ${isSafari 
            ? `0.1px 0.1px 0.2px rgba(0,0,0,0.4),
               0 0 3px ${theme.palette.primary.main},
               0 0 8px ${theme.palette.primary.main},
               0 0 12px ${theme.palette.secondary.main}`
            : `0.1px 0.1px 0.2px rgba(0,0,0,0.8),
               0 0 5px ${theme.palette.primary.main},
               0 0 10px ${theme.palette.primary.main},
               0 0 15px ${theme.palette.secondary.main}`};
        opacity: ${isSafari ? '0.9' : '0.8'};
    }
    50% {
        text-shadow: ${isSafari 
            ? `0.1px 0.1px 0.2px rgba(0,0,0,0.4),
               0 0 5px ${theme.palette.primary.main},
               0 0 10px ${theme.palette.primary.main},
               0 0 15px ${theme.palette.secondary.main}`
            : `0.1px 0.1px 0.2px rgba(0,0,0,0.8),
               0 0 8px ${theme.palette.primary.main},
               0 0 13px ${theme.palette.primary.main},
               0 0 18px ${theme.palette.secondary.main}`};
        opacity: 1;
    }
    100% {
        text-shadow: ${isSafari 
            ? `0.1px 0.1px 0.2px rgba(0,0,0,0.4),
               0 0 3px ${theme.palette.primary.main},
               0 0 8px ${theme.palette.primary.main},
               0 0 12px ${theme.palette.secondary.main}`
            : `0.1px 0.1px 0.2px rgba(0,0,0,0.8),
               0 0 5px ${theme.palette.primary.main},
               0 0 10px ${theme.palette.primary.main},
               0 0 15px ${theme.palette.secondary.main}`};
        opacity: ${isSafari ? '0.9' : '0.8'};
    }
`;

// Define the moving gradient animation
export const movingGradientAnimation = keyframes`
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
`; 