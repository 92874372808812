import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';

// Declare the global gtag function for TypeScript
declare global {
  interface Window {
    gtag: (
      command: string,
      action: string,
      params?: Record<string, any>
    ) => void;
    dataLayer: any[];
  }
}

/**
 * A hook that tracks page views with Google Analytics when the route changes
 */
const useGoogleAnalytics = () => {
  const location = useLocation();
  const prevPathRef = useRef<string>('');

  useEffect(() => {
    const currentPath = location.pathname;
    
    // Avoid sending duplicate page views for the same path
    if (prevPathRef.current !== currentPath && window.gtag) {
      prevPathRef.current = currentPath;
      
      // Send page view event to Google Analytics
      window.gtag('event', 'page_view', {
        page_path: currentPath,
        page_location: window.location.href,
        page_title: document.title,
      });
    }
  }, [location]);
};

export default useGoogleAnalytics; 