import React, { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../context/AuthProvider';
import { Stack, Paper, CircularProgress, Skeleton, Box } from '@mui/material';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { SubscriptionResponse } from '../types/types';
import CoffeeIcon from '@mui/icons-material/Coffee';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import { getCheckoutUrl } from '../services/StripeService';
import SubscriptionCard from './SubscriptionCard';
import { Plan } from '../types/subscription';

interface SubscriptionsListProps {
    noTrial?: boolean;
}

const SubscriptionCardSkeleton: React.FC<{ highlighted?: boolean }> = ({ highlighted }) => (
    <Paper
        elevation={highlighted ? 8 : 3}
        sx={{
            position: 'relative',
            p: 4,
            height: 580,
            display: 'flex',
            flexDirection: 'column',
            background: highlighted
                ? 'linear-gradient(135deg, #212121 0%, #333135 100%)'
                : '#212121',
            borderRadius: 4,
            border: '1px solid',
            borderColor: highlighted ? 'rgba(255, 255, 255, 0.1)' : 'rgba(255, 255, 255, 0.05)',
        }}
    >
        <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
            {/* Header Skeleton */}
            <Box>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 2 }}>
                    <Skeleton variant="circular" width={32} height={32} />
                    <Skeleton variant="text" width={200} sx={{ fontSize: '1.5rem' }} />
                </Box>
                <Skeleton variant="text" width="90%" sx={{ mb: 1 }} />
                <Skeleton variant="text" width="70%" />
            </Box>

            {/* Price Skeleton */}
            <Box sx={{ mt: 3 }}>
                <Skeleton variant="text" width={150} height={60} />
                <Skeleton variant="text" width={100} />
            </Box>

            {/* Features Skeleton */}
            <Stack spacing={2} sx={{ mt: 3, mb: 'auto' }}>
                {[1, 2, 3, 4, 5].map((index) => (
                    <Box key={index} sx={{ display: 'flex', alignItems: 'center', gap: 1.5 }}>
                        <Skeleton variant="circular" width={20} height={20} />
                        <Skeleton variant="text" width={`${Math.random() * 30 + 70}%`} />
                    </Box>
                ))}
            </Stack>

            {/* Button Skeleton */}
            <Box sx={{ mt: 4 }}>
                <Skeleton 
                    variant="rounded" 
                    width="100%" 
                    height={50}
                    sx={{
                        bgcolor: highlighted ? 'rgba(0, 255, 0, 0.1)' : undefined
                    }}
                />
            </Box>
        </Box>
    </Paper>
);

const SubscriptionsList: React.FC<SubscriptionsListProps> = ({ noTrial = false }) => {
    const navigate = useNavigate();
    const authContext = useContext(AuthContext);
    const { user, getSubscriptionStatus, app, setCouponCode, couponCode, userCurrency, prices, loadingPrices } = authContext || {};
    const [subscription, setSubscription] = React.useState<SubscriptionResponse | null>(null);
    const [loadingSubscription, setLoadingSubscription] = React.useState(false);
    const [loadingCheckout, setLoadingCheckout] = useState(false);
    const [searchParams] = useSearchParams();

    // Handle coupon code from URL parameters
    useEffect(() => {
        const couponFromUrl = searchParams.get('coupon');
        if (couponFromUrl && setCouponCode) {
            setCouponCode(couponFromUrl);
        }
    }, [searchParams, setCouponCode]);

    useEffect(() => {
        if (couponCode && setCouponCode) {
            setCouponCode(couponCode);
        }
    }, [couponCode, setCouponCode]);

    useEffect(() => {
        if (user && getSubscriptionStatus && app) {
            setLoadingSubscription(true);
            getSubscriptionStatus(app).then((subscriptionData) => {
                setSubscription(subscriptionData);
                setLoadingSubscription(false);
            });
        } else {
            setLoadingSubscription(false);
            setSubscription(null);
        }
    }, [user, getSubscriptionStatus, app]);

    const handleSubscribe = async () => {
        if (!app || !user) {
            navigate('/signup');
            return;
        }

        const price = getCurrentPrice();
        if (!price?.priceId) {
            console.error('No price ID available');
            return;
        }

        // Track subscription button click
        if (window.gtag) {
            window.gtag('event', 'begin_checkout', {
                event_category: 'conversion',
                event_label: 'Subscription Checkout',
                currency: userCurrency,
                value: price.amount,
                items: [{
                    item_name: 'SonaScript Subscription',
                    price: price.amount,
                    currency: userCurrency
                }]
            });
        }

        try {
            setLoadingCheckout(true);
            const checkoutUrl = await getCheckoutUrl(app, price.priceId, couponCode);
            window.location.href = checkoutUrl;
        } catch (error) {
            console.error('Error creating checkout session:', error);
            setLoadingCheckout(false);
        }
    };

    const getCurrentPrice = () => {
        const price = prices?.find((p) => p.currency === userCurrency);
        if (!price) return null;

        const amount = price.unit_amount / 100;
        const symbol = userCurrency === 'eur' ? '€' : '$';

        return {
            amount,
            symbol,
            priceId: price.id,
            formattedPrice: `${symbol}${amount}`,
        };
    };

    const getPlans = (): Plan[] => {
        const price = getCurrentPrice();
        const isWhisperPromo = couponCode?.toLowerCase() === process.env.REACT_APP_STRIPE_PROMOTION_CODE?.toLowerCase();

        const trialPlan: Plan = {
            title: 'Continue with Trial',
            price: `${price?.symbol || '€'}0.00`,
            period: '/month',
            type: 'trial',
            description: 'Experience SonaScript with core features to boost your productivity',
            features: [
                { text: 'No credit card required' },
                { text: 'Full access for first 72 hours' },
                { text: 'Continue in cycles of 2 hours of active use followed by a 2-hour pause.' },
            ],
            buttonText: 'CONTINUE WITHOUT SUBSCRIPTION',
            buttonLink: '/download',
            icon: <AutoAwesomeIcon sx={{ fontSize: 32 }} />,
        };

        const coffeeSubscription: Plan = {
            title: 'Coffee Subscription',
            price: price?.formattedPrice || null,
            period: '/month',
            type: 'paid',
            description: 'Unlock the full potential of SonaScript with premium features',
            features: [
                { text: 'Unlimited usage 24/7', highlight: true },
                { text: 'Cloud-powered or Local mode transcription', highlight: true },
                { text: 'Advanced AI text processing', highlight: true },
                { text: 'Custom AI prompts & templates', highlight: true },
                { text: 'Global shortcuts for transcription and AI processing', highlight: true },
            ],
            buttonText: loadingCheckout ? 'REDIRECTING...' : 'GET COFFEE SUBSCRIPTION',
            buttonLink: '#',
            icon: <CoffeeIcon sx={{ fontSize: 32 }} />,
            highlighted: true,
        };

        const whisperPromo: Plan = {
            title: 'Whisper to ChatGPT Special',
            price: price?.formattedPrice || null,
            period: '/month',
            type: 'paid',
            description: `Special offer for Whisper to ChatGPT users - First month free, then ${price?.formattedPrice}`,
            features: [
                { text: 'First month completely FREE', highlight: true },
                { text: 'Unlimited usage 24/7', highlight: true },
                { text: 'Cloud-powered or Local mode transcription', highlight: true },
                { text: 'Advanced AI text processing', highlight: true },
                { text: 'Global shortcuts for transcription and AI processing', highlight: true },
            ],
            buttonText: loadingCheckout ? 'REDIRECTING...' : 'CLAIM SPECIAL OFFER',
            buttonLink: '#',
            icon: <CoffeeIcon sx={{ fontSize: 32 }} />,
            highlighted: true,
            isSpecialOffer: true,
        };

        return isWhisperPromo ? [whisperPromo, trialPlan] : [coffeeSubscription, trialPlan];
    };

    if (loadingPrices) {
        return (
            <Stack
                direction={{ xs: 'column', md: 'row' }}
                spacing={4}
                alignItems="stretch"
                justifyContent="center"
                sx={{
                    px: { xs: 2, md: 0 },
                    '& > :first-of-type': {
                        width: { xs: '100%', md: 500 },
                    },
                    '& > :not(:first-of-type)': {
                        width: { xs: '100%', md: 400 },
                    },
                }}
            >
                <SubscriptionCardSkeleton highlighted />
                <SubscriptionCardSkeleton />
            </Stack>
        );
    }

    const plans = getPlans();

    return (
        <Stack
            direction={{ xs: 'column', md: 'row' }}
            spacing={4}
            alignItems="stretch"
            justifyContent="center"
            sx={{
                px: { xs: 2, md: 0 },
                '& > :first-of-type': {
                    width: { xs: '100%', md: 500 }, // Make the first card (Whisper promo) wider
                },
                '& > :not(:first-of-type)': {
                    width: { xs: '100%', md: 400 }, // Keep other cards at normal width
                },
            }}
        >
            {plans.map((plan, index) =>
                noTrial && plan.type === 'trial' ? null : (
                    <SubscriptionCard key={index} plan={plan} onSubscribe={handleSubscribe} onNavigate={navigate} loadingCheckout={loadingCheckout} />
                )
            )}
        </Stack>
    );
};

export default SubscriptionsList;
