import React from 'react';
import { Box, Typography, Paper, Grid, useTheme } from '@mui/material';

interface Feature {
    title: string;
    description: string;
    imageSrc: string;
    bulletPoints?: string[];
}

interface FeatureSectionProps {
    features: Feature[];
}

const gradientVariants = [
    {
        background: (theme: any) => `linear-gradient(145deg, 
            ${theme.palette.background.paper} 0%, 
            ${theme.palette.background.default} 100%
        )`,
        glow: (theme: any) => `radial-gradient(circle, ${theme.palette.primary.main}22 0%, transparent 70%)`
    },
    {
        background: (theme: any) => `linear-gradient(165deg, 
            ${theme.palette.background.default} 0%, 
            ${theme.palette.background.paper} 50%,
            ${theme.palette.background.default} 100%
        )`,
        glow: (theme: any) => `radial-gradient(circle, ${theme.palette.secondary.main}22 0%, transparent 70%)`
    },
    {
        background: (theme: any) => `linear-gradient(125deg, 
            ${theme.palette.background.paper} 0%, 
            ${theme.palette.background.default} 50%,
            ${theme.palette.background.paper} 100%
        )`,
        glow: (theme: any) => `radial-gradient(circle, ${theme.palette.primary.light}22 0%, transparent 70%)`
    },
    {
        background: (theme: any) => `linear-gradient(155deg, 
            ${theme.palette.background.default} 0%, 
            ${theme.palette.background.paper} 100%
        )`,
        glow: (theme: any) => `radial-gradient(circle, ${theme.palette.secondary.light}22 0%, transparent 70%)`
    }
];

const FeatureSection: React.FC<FeatureSectionProps> = ({ features }) => {
    const theme = useTheme();

    return (
        <Box 
            id="features" 
            sx={{ 
                pt: { xs: 12, md: 16 },
                pb: { xs: 8, md: 12 },
                position: 'relative',
                '&::before': {
                    content: '""',
                    position: 'absolute',
                    top: 0,
                    left: '50%',
                    transform: 'translateX(-50%)',
                    width: '80%',
                    height: '1px',
                    background: `linear-gradient(90deg, 
                        transparent 0%, 
                        rgba(255, 255, 255, 0.05) 20%, 
                        rgba(255, 255, 255, 0.1) 50%, 
                        rgba(255, 255, 255, 0.05) 80%, 
                        transparent 100%
                    )`,
                }
            }}
        >
            <Typography 
                variant="h3" 
                component="h2" 
                align="center" 
                gutterBottom
                sx={{
                    fontWeight: 'bold',
                    background: `linear-gradient(45deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
                    WebkitBackgroundClip: 'text',
                    WebkitTextFillColor: 'transparent',
                    mb: 2
                }}
            >
                Powerful Features
            </Typography>
            <Typography 
                variant="h6" 
                align="center" 
                color="text.secondary" 
                sx={{ 
                    mb: 8,
                    maxWidth: '800px',
                    mx: 'auto',
                    px: 3
                }}
            >
                Everything you need for efficient voice-to-text conversion, with the power of AI at your fingertips
            </Typography>
            {features.map((feature, index) => (
                <Box
                    key={index}
                    sx={{
                        position: 'relative',
                        '&:hover': {
                            '& .feature-content': {
                                transform: 'translateY(-4px)',
                            },
                            '& .feature-glow::before': {
                                opacity: 0.15,
                            }
                        }
                    }}
                >
                    <Paper 
                        elevation={3} 
                        className="feature-content"
                        sx={{ 
                            p: 4,
                            my: 6,
                            background: gradientVariants[index % gradientVariants.length].background(theme),
                            transition: 'transform 0.3s ease-in-out',
                            position: 'relative',
                            zIndex: 1,
                            borderRadius: 4,
                            border: '1px solid',
                            borderColor: 'rgba(255, 255, 255, 0.05)'
                        }}
                    >
                        <Grid 
                            container 
                            spacing={4} 
                            alignItems="center" 
                            direction={index % 2 === 0 ? 'row' : 'row-reverse'}
                        >
                            <Grid item xs={12} md={6}>
                                <Typography 
                                    variant="h4" 
                                    gutterBottom
                                    sx={{
                                        fontWeight: 'bold',
                                        mb: 3
                                    }}
                                >
                                    {feature.title}
                                </Typography>
                                <Typography 
                                    variant="body1" 
                                    sx={{ 
                                        color: 'text.secondary',
                                        fontSize: '1.1rem',
                                        lineHeight: 1.6,
                                        mb: 3
                                    }}
                                >
                                    {feature.description}
                                </Typography>
                                {feature.bulletPoints && (
                                    <Box sx={{ pl: 2 }}>
                                        {feature.bulletPoints.map((point, idx) => (
                                            <Box 
                                                key={idx} 
                                                sx={{ 
                                                    display: 'flex',
                                                    alignItems: 'flex-start',
                                                    mb: 1.5
                                                }}
                                            >
                                                <Box 
                                                    sx={{ 
                                                        width: 6,
                                                        height: 6,
                                                        borderRadius: '50%',
                                                        bgcolor: 'primary.main',
                                                        mt: 1.2,
                                                        mr: 2,
                                                        flexShrink: 0
                                                    }} 
                                                />
                                                <Typography 
                                                    variant="body1"
                                                    sx={{ 
                                                        color: 'text.secondary',
                                                        fontSize: '1rem',
                                                        lineHeight: 1.6
                                                    }}
                                                >
                                                    {point}
                                                </Typography>
                                            </Box>
                                        ))}
                                    </Box>
                                )}
                            </Grid>
                            <Grid 
                                item 
                                xs={12} 
                                md={6} 
                                sx={{ 
                                    display: 'flex', 
                                    justifyContent: 'center',
                                    position: 'relative'
                                }}
                            >
                                <Box
                                    className="feature-glow"
                                    sx={{
                                        position: 'relative',
                                        width: '100%',
                                        '&::before': {
                                            content: '""',
                                            position: 'absolute',
                                            top: -20,
                                            left: -20,
                                            right: -20,
                                            bottom: -20,
                                            background: gradientVariants[index % gradientVariants.length].glow(theme),
                                            opacity: 0.1,
                                            transition: 'opacity 0.3s ease-in-out',
                                            zIndex: 0
                                        }
                                    }}
                                >
                                    {feature.imageSrc.endsWith('.mp4') ? (
                                        <Box
                                            component="video"
                                            autoPlay
                                            loop
                                            muted
                                            sx={{
                                                width: '100%',
                                                borderRadius: 3,
                                                boxShadow: 3,
                                                position: 'relative',
                                                zIndex: 1
                                            }}
                                        >
                                            <source src={feature.imageSrc} type="video/mp4" />
                                            Your browser does not support the video tag.
                                        </Box>
                                    ) : (
                                        <Box
                                            component="img"
                                            sx={{
                                                width: '100%',
                                                borderRadius: 3,
                                                boxShadow: 3,
                                                position: 'relative',
                                                zIndex: 1
                                            }}
                                            alt={feature.title}
                                            src={feature.imageSrc}
                                        />
                                    )}
                                </Box>
                            </Grid>
                        </Grid>
                    </Paper>
                </Box>
            ))}
        </Box>
    );
};

export default FeatureSection; 