import React, { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../context/AuthProvider';
import { Box, Container, Typography, useTheme, Skeleton, Stack } from '@mui/material';
import { SubscriptionResponse } from '../types/types';
import Subscription from './SubscriptionComponent';
import SubscriptionsList from './SubscriptionsList';

// Declare the global gtag function for TypeScript
declare global {
    interface Window {
        gtag: (
            command: string,
            action: string,
            params?: Record<string, any>
        ) => void;
        dataLayer: any[];
    }
}

const Profile: React.FC = () => {
    const theme = useTheme();
    const authContext = useContext(AuthContext);
    const { user, getSubscriptionStatus, removeRegisteredDevice, app, loading } = authContext || {};
    const navigate = useNavigate();
    const [subscription, setSubscription] = React.useState<SubscriptionResponse>();
    const [loadingSubscription, setLoadingSubscription] = React.useState(true);
    const [shouldloadSubscription, setShouldLoadSubscription] = React.useState(true);

    useEffect(() => {
        // Trigger Google Ads conversion event when profile page loads
        if (window.gtag) {
            window.gtag('event', 'ads_conversion_Sign_up_1', {
                // Add any additional parameters if needed
            });
        }
    }, []);

    useEffect(() => {
        if (!user) {
            navigate('/login');
        }
        if (user && getSubscriptionStatus && app && shouldloadSubscription) {
            setLoadingSubscription(true);
            getSubscriptionStatus(app).then((subscription) => {
                setSubscription(subscription);
                setLoadingSubscription(false);
            });
            setShouldLoadSubscription(false);
        }
    }, [user, navigate, shouldloadSubscription]);

    const handleDeleteDevice = async (deviceId: string) => {
        if (app && deviceId && removeRegisteredDevice) {
            setLoadingSubscription(true);
            removeRegisteredDevice(app, deviceId).then(() => {
                setShouldLoadSubscription(true);
            });
        }
    };

    return (
        <Box
            sx={{
                minHeight: 'calc(100vh - 160px)',
                background: `linear-gradient(135deg, 
                    ${theme.palette.background.paper} 0%,
                    ${theme.palette.background.default} 100%
                )`,
                position: 'relative',
                pt: { xs: 8, md: 12 },
                pb: { xs: 8, md: 12 },
                '&::before': {
                    content: '""',
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    height: '200px',
                    background: `linear-gradient(180deg, ${theme.palette.background.default} 0%, transparent 100%)`,
                    opacity: 0.4,
                    pointerEvents: 'none',
                },
                '&::after': {
                    content: '""',
                    position: 'absolute',
                    bottom: 0,
                    left: 0,
                    right: 0,
                    height: '200px',
                    background: `linear-gradient(0deg, ${theme.palette.background.default} 0%, transparent 100%)`,
                    opacity: 0.4,
                    pointerEvents: 'none',
                },
            }}
        >
            <Container maxWidth="lg">
                <Box
                    sx={{
                        position: 'relative',
                        zIndex: 1,
                        textAlign: 'center',
                    }}
                >
                    <Typography
                        variant="h4"
                        component="h1"
                        gutterBottom
                        sx={{
                            fontWeight: 'bold',
                            mb: 4,
                            background: `linear-gradient(45deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
                            WebkitBackgroundClip: 'text',
                            WebkitTextFillColor: 'transparent',
                        }}
                    >
                        Welcome, {user?.displayName ? user?.displayName : user?.email}
                    </Typography>

                    {!loading && !loadingSubscription ? (
                        <Box>
                            {subscription && (
                                <Box
                                    sx={{
                                        position: 'relative',
                                        '&::before': {
                                            content: '""',
                                            position: 'absolute',
                                            top: '50%',
                                            left: '50%',
                                            transform: 'translate(-50%, -50%)',
                                            width: '200%',
                                            height: '200%',
                                            background: `radial-gradient(ellipse at center, 
                                            ${theme.palette.primary.main}11 0%, 
                                            ${theme.palette.secondary.main}11 25%, 
                                            transparent 70%
                                        )`,
                                            opacity: 0.5,
                                            zIndex: -1,
                                        },
                                    }}
                                >
                                    <Subscription subscription={subscription} onDeleteDevice={handleDeleteDevice} />
                                </Box>
                            )}

                            {subscription && subscription.is_trial && (
                                <Box sx={{ mt: 6 }}>
                                    <SubscriptionsList noTrial={true} />
                                </Box>
                            )}
                        </Box>
                    ) : (
                        <Box sx={{ maxWidth: '800px', mx: 'auto' }}>
                            <Stack spacing={6}>
                                {/* Subscription Info Skeleton */}
                                <Box sx={{ textAlign: 'center' }}>
                                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: 1.5, mb: 3 }}>
                                        <Skeleton variant="circular" width={32} height={32} />
                                        <Skeleton variant="text" width={200} sx={{ fontSize: '2rem' }} />
                                    </Box>
                                    <Box sx={{ mb: 3 }}>
                                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: 2, mb: 2 }}>
                                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                                <Skeleton variant="circular" width={20} height={20} />
                                                <Skeleton variant="text" width={180} />
                                            </Box>
                                            <Skeleton variant="circular" width={4} height={4} />
                                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                                <Skeleton variant="circular" width={20} height={20} />
                                                <Skeleton variant="rounded" width={80} height={24} />
                                            </Box>
                                        </Box>
                                        <Skeleton variant="text" width="60%" sx={{ mx: 'auto' }} />
                                    </Box>
                                    <Skeleton variant="rounded" width={220} height={40} sx={{ mx: 'auto' }} />
                                </Box>

                                {/* Devices List Skeleton */}
                                <Box>
                                    <Skeleton
                                        variant="text"
                                        width={200}
                                        sx={{
                                            mx: 'auto',
                                            mb: 3,
                                            fontSize: '1.5rem',
                                        }}
                                    />
                                    <Stack
                                        spacing={2}
                                        sx={{
                                            maxWidth: 600,
                                            mx: 'auto',
                                        }}
                                    >
                                        {[1, 2].map((i) => (
                                            <Box
                                                key={i}
                                                sx={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'space-between',
                                                    py: 2,
                                                    px: 3,
                                                    borderRadius: 2,
                                                    backgroundColor: 'rgba(255, 255, 255, 0.03)',
                                                    border: '1px solid',
                                                    borderColor: 'rgba(255, 255, 255, 0.05)',
                                                }}
                                            >
                                                <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                                                    <Skeleton variant="circular" width={24} height={24} />
                                                    <Skeleton variant="text" width={200} />
                                                </Box>
                                                <Skeleton variant="rounded" width={100} height={36} />
                                            </Box>
                                        ))}
                                    </Stack>
                                </Box>
                            </Stack>
                        </Box>
                    )}
                </Box>
            </Container>
        </Box>
    );
};

export default Profile;
