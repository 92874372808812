// src/App.tsx
import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import AuthProvider from './context/AuthProvider';
import createTheme from '@mui/material/styles/createTheme';
import NavBar from './components/NavbarComponent';
import PrivateRoute from './components/PrivateRoute';
import darkThemeOptions from './Themes';
import ThemeProvider from '@mui/material/styles/ThemeProvider';
import CssBaseline from '@mui/material/CssBaseline';
import Profile from './components/ProfilePage';
import DownloadPage from './components/DownloadPage';
import SonaScriptLandingPage from './components/SonaScriptLandingPage';
import LoginRoute from './components/LoginRoute';
import SignUp from './components/SignUpComponent';
import AuthRedirect from './components/AuthRedirect';
import Privacy from './components/Privacy';
import Terms from './components/Terms';
import Footer from './components/FooterComponent';
import { Box } from '@mui/material';
import useGoogleAnalytics from './hooks/useGoogleAnalytics';
import ConsentBanner from './components/ConsentBanner';
import ConsentDebugger from './components/ConsentDebugger';

const darkTheme = createTheme(darkThemeOptions);

// Component to handle analytics tracking
const AnalyticsTracker = () => {
    useGoogleAnalytics();
    return null;
};

const App: React.FC = () => {
    return (
        <HelmetProvider>
            <Router>
                <ThemeProvider theme={darkTheme}>
                    <CssBaseline />
                    <AuthProvider>
                        <AnalyticsTracker />
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                minHeight: '100vh',
                                position: 'relative',
                                overflow: 'hidden'
                            }}
                        >
                            <NavBar />
                            <Box
                                component="main"
                                sx={{
                                    flex: 1,
                                    display: 'flex',
                                    flexDirection: 'column'
                                }}
                            >
                                <Routes>
                                    <Route path="/" element={<SonaScriptLandingPage />} />
                                    <Route path="/auth-redirect" element={<AuthRedirect />} />
                                    <Route
                                        path="/signup"
                                        element={
                                            <LoginRoute>
                                                <SignUp />
                                            </LoginRoute>
                                        }
                                    />
                                    <Route
                                        path="/profile"
                                        element={
                                            <PrivateRoute>
                                                <Profile />
                                            </PrivateRoute>
                                        }
                                    />
                                    <Route path="/download" element={<DownloadPage isMainPage={false} />} />
                                    <Route path="/terms" element={<Terms />} />
                                    <Route path="/privacy" element={<Privacy />} />
                                    <Route path="*" element={<Navigate to="/" replace />} />
                                </Routes>
                            </Box>
                            <Footer />
                            <ConsentBanner />
                            <ConsentDebugger />
                        </Box>
                    </AuthProvider>
                </ThemeProvider>
            </Router>
        </HelmetProvider>
    );
};

export default App;
