import React from 'react';
import { Container, Typography, Box, List, ListItem, ListItemText, useTheme } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledLink = styled('a')(({ theme }) => ({
    color: theme.palette.primary.main,
    textDecoration: 'none',
    '&:hover': {
        textDecoration: 'underline',
    },
}));

const Privacy: React.FC = () => {
    const theme = useTheme();

    return (
        <Box
            sx={{
                minHeight: 'calc(100vh - 160px)',
                background: `linear-gradient(135deg, 
                    ${theme.palette.background.paper} 0%,
                    ${theme.palette.background.default} 100%
                )`,
                position: 'relative',
                pt: { xs: 8, md: 12 },
                pb: { xs: 8, md: 12 },
                '&::before': {
                    content: '""',
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    height: '200px',
                    background: `linear-gradient(180deg, ${theme.palette.background.default} 0%, transparent 100%)`,
                    opacity: 0.4,
                    pointerEvents: 'none',
                },
                '&::after': {
                    content: '""',
                    position: 'absolute',
                    bottom: 0,
                    left: 0,
                    right: 0,
                    height: '200px',
                    background: `linear-gradient(0deg, ${theme.palette.background.default} 0%, transparent 100%)`,
                    opacity: 0.4,
                    pointerEvents: 'none',
                }
            }}
        >
            <Container maxWidth="md">
                <Box 
                    sx={{ 
                        position: 'relative',
                        zIndex: 1,
                        '& .MuiListItem-root': { py: 0.5 } 
                    }}
                >
                    <Typography 
                        variant="h3" 
                        component="h1" 
                        gutterBottom
                        sx={{
                            fontWeight: 'bold',
                            mb: 4,
                            background: `linear-gradient(45deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
                            WebkitBackgroundClip: 'text',
                            WebkitTextFillColor: 'transparent',
                        }}
                    >
                        Privacy Policy
                    </Typography>

                    <Typography variant="subtitle1" gutterBottom>
                        Last Updated: 26 November 2024
                    </Typography>

                    <Typography variant="body1" paragraph>
                        At Sonascript, we respect your privacy and are committed to protecting your personal information. This Privacy Policy outlines how we
                        collect, use, and safeguard your data when you use our App and Services.
                    </Typography>

                    <Typography variant="h6" gutterBottom>
                        1. Information We Collect
                    </Typography>
                    <List disablePadding>
                        <ListItem sx={{ pl: 4, display: 'flex', alignItems: 'flex-start' }}>
                            <Typography sx={{ mr: 1 }}>•</Typography>
                            <ListItemText
                                primary={
                                    <>
                                        <strong>Personal Information</strong>: Such as your name, email address, and any other information you provide during account
                                        registration.
                                    </>
                                }
                            />
                        </ListItem>
                        <ListItem sx={{ pl: 4, display: 'flex', alignItems: 'flex-start' }}>
                            <Typography sx={{ mr: 1 }}>•</Typography>
                            <ListItemText
                                primary={
                                    <>
                                        <strong>Usage Data</strong>: Information on how you access and use the App, including device information, logs, and other diagnostic
                                        data.
                                    </>
                                }
                            />
                        </ListItem>
                    </List>

                    <Typography variant="h6" gutterBottom>
                        2. How We Use Your Information
                    </Typography>
                    <List disablePadding>
                        <ListItem sx={{ pl: 4, display: 'flex', alignItems: 'flex-start' }}>
                            <Typography sx={{ mr: 1 }}>•</Typography>
                            <ListItemText primary="To provide and maintain our Services." />
                        </ListItem>
                        <ListItem sx={{ pl: 4, display: 'flex', alignItems: 'flex-start' }}>
                            <Typography sx={{ mr: 1 }}>•</Typography>
                            <ListItemText primary="To improve and personalize your experience." />
                        </ListItem>
                        <ListItem sx={{ pl: 4, display: 'flex', alignItems: 'flex-start' }}>
                            <Typography sx={{ mr: 1 }}>•</Typography>
                            <ListItemText primary="To communicate with you regarding updates, offers, or customer support." />
                        </ListItem>
                        <ListItem sx={{ pl: 4, display: 'flex', alignItems: 'flex-start' }}>
                            <Typography sx={{ mr: 1 }}>•</Typography>
                            <ListItemText primary="To monitor the usage of the App and detect, prevent, and address technical issues." />
                        </ListItem>
                    </List>

                    <Typography variant="h6" gutterBottom>
                        3. Data Sharing and Disclosure
                    </Typography>
                    <List disablePadding>
                        <ListItem sx={{ pl: 4, display: 'flex', alignItems: 'flex-start' }}>
                            <Typography sx={{ mr: 1 }}>•</Typography>
                            <ListItemText
                                primary={
                                    <>
                                        <strong>Third-Party Service Providers</strong>: We may share your information with third parties like OpenAI solely for the purpose of
                                        providing the Services.
                                    </>
                                }
                            />
                        </ListItem>
                        <ListItem sx={{ pl: 4, display: 'flex', alignItems: 'flex-start' }}>
                            <Typography sx={{ mr: 1 }}>•</Typography>
                            <ListItemText
                                primary={
                                    <>
                                        <strong>Legal Requirements</strong>: We may disclose your information if required by law or in response to valid requests by public
                                        authorities.
                                    </>
                                }
                            />
                        </ListItem>
                        <ListItem sx={{ pl: 4, display: 'flex', alignItems: 'flex-start' }}>
                            <Typography sx={{ mr: 1 }}>•</Typography>
                            <ListItemText
                                primary={
                                    <>
                                        <strong>Business Transfers</strong>: In the event of a merger or acquisition, your information may be transferred.
                                    </>
                                }
                            />
                        </ListItem>
                    </List>

                    <Typography variant="h6" gutterBottom>
                        4. Data Security
                    </Typography>
                    <List disablePadding>
                        <ListItem sx={{ pl: 4, display: 'flex', alignItems: 'flex-start' }}>
                            <Typography sx={{ mr: 1 }}>•</Typography>
                            <ListItemText primary="We implement reasonable security measures to protect your data." />
                        </ListItem>
                        <ListItem sx={{ pl: 4, display: 'flex', alignItems: 'flex-start' }}>
                            <Typography sx={{ mr: 1 }}>•</Typography>
                            <ListItemText primary="However, no method of transmission over the Internet is completely secure, and we cannot guarantee absolute security." />
                        </ListItem>
                    </List>

                    <Typography variant="h6" gutterBottom>
                        5. Data Retention
                    </Typography>
                    <List disablePadding>
                        <ListItem sx={{ pl: 4, display: 'flex', alignItems: 'flex-start' }}>
                            <Typography sx={{ mr: 1 }}>•</Typography>
                            <ListItemText primary="We retain your personal information only for as long as necessary to fulfill the purposes outlined in this Privacy Policy." />
                        </ListItem>
                        <ListItem sx={{ pl: 4, display: 'flex', alignItems: 'flex-start' }}>
                            <Typography sx={{ mr: 1 }}>•</Typography>
                            <ListItemText primary="Audio data, transcription results, and follow-up prompt results may be stored temporarily for App usage and then deleted." />
                        </ListItem>
                    </List>

                    <Typography variant="h6" gutterBottom>
                        6. Your Rights
                    </Typography>
                    <List disablePadding>
                        <ListItem sx={{ pl: 4, display: 'flex', alignItems: 'flex-start' }}>
                            <Typography sx={{ mr: 1 }}>•</Typography>
                            <ListItemText primary="You have the right to access, correct, or delete your personal information." />
                        </ListItem>
                        <ListItem sx={{ pl: 4, display: 'flex', alignItems: 'flex-start' }}>
                            <Typography sx={{ mr: 1 }}>•</Typography>
                            <ListItemText primary="You may opt out of receiving promotional communications from us." />
                        </ListItem>
                    </List>

                    <Typography variant="h6" gutterBottom>
                        7. Children's Privacy
                    </Typography>
                    <List disablePadding>
                        <ListItem sx={{ pl: 4, display: 'flex', alignItems: 'flex-start' }}>
                            <Typography sx={{ mr: 1 }}>•</Typography>
                            <ListItemText primary="Our Services are not intended for use by children under the age of 13." />
                        </ListItem>
                        <ListItem sx={{ pl: 4, display: 'flex', alignItems: 'flex-start' }}>
                            <Typography sx={{ mr: 1 }}>•</Typography>
                            <ListItemText primary="We do not knowingly collect personal information from children under 13." />
                        </ListItem>
                    </List>

                    <Typography variant="h6" gutterBottom>
                        8. International Data Transfer
                    </Typography>
                    <List disablePadding>
                        <ListItem sx={{ pl: 4, display: 'flex', alignItems: 'flex-start' }}>
                            <Typography sx={{ mr: 1 }}>•</Typography>
                            <ListItemText primary="Your information may be transferred to and maintained on servers located outside of your state or country." />
                        </ListItem>
                        <ListItem sx={{ pl: 4, display: 'flex', alignItems: 'flex-start' }}>
                            <Typography sx={{ mr: 1 }}>•</Typography>
                            <ListItemText primary="By using the App, you consent to such transfers." />
                        </ListItem>
                    </List>

                    <Typography variant="h6" gutterBottom>
                        9. Changes to This Privacy Policy
                    </Typography>
                    <List disablePadding>
                        <ListItem sx={{ pl: 4, display: 'flex', alignItems: 'flex-start' }}>
                            <Typography sx={{ mr: 1 }}>•</Typography>
                            <ListItemText primary="We may update our Privacy Policy from time to time." />
                        </ListItem>
                        <ListItem sx={{ pl: 4, display: 'flex', alignItems: 'flex-start' }}>
                            <Typography sx={{ mr: 1 }}>•</Typography>
                            <ListItemText primary='We will notify you of any changes by updating the "Last Updated" date.' />
                        </ListItem>
                        <ListItem sx={{ pl: 4, display: 'flex', alignItems: 'flex-start' }}>
                            <Typography sx={{ mr: 1 }}>•</Typography>
                            <ListItemText primary="Your continued use of the App constitutes acceptance of the new Privacy Policy." />
                        </ListItem>
                    </List>

                    <Typography variant="h6" gutterBottom>
                        10. Contact Us
                    </Typography>
                    <List disablePadding>
                        <ListItem sx={{ pl: 4, display: 'flex', alignItems: 'flex-start' }}>
                            <Typography sx={{ mr: 1 }}>•</Typography>
                            <ListItemText
                                primary={
                                    <>
                                        If you have any questions about this Privacy Policy or the App, please contact us at:
                                        <br />
                                        • Email: <StyledLink href="mailto:sonascript@gmail.com">sonascript@gmail.com</StyledLink>
                                        <br />
                                        • Website: <StyledLink href="https://sonascript.com">sonascript.com</StyledLink>
                                    </>
                                }
                            />
                        </ListItem>
                    </List>
                </Box>
            </Container>
        </Box>
    );
};

export default Privacy;
